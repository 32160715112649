import React, { useEffect, useState } from "react";
import Select from "react-select";
import settings, {
  toastDocarnJuridique,
} from "../../config/settings";
import {
  addDocumentResAPI,
  infoFormConnectedResAPI,
} from "../../api/docarnJuridiqueApi";
import { getModeList } from "../../store/configuration/ConfigurationSlice";
import { useSelector } from "react-redux";
import {
  getAuthentificated,
  selectCurrentUser,
} from "../../store/user/UserSlice";
import { useDispatch } from "react-redux";
import {
  setCurrentPage,
  setData,
  setLinks,
  setNextPageUrl,
  setPrevPageUrl,
  setSizePage,
} from "../../store/page/PageSlice";
import moment from 'moment';
import SpinnerApp from "../spinner/SpinnerApp";
// import { DisplayModeDark } from "../../store/reducers/configuration/configuration.actions";

const Documentation = () => {
  const [showSpinnerApp, setShowSpinnerApp] = useState(false);
  const [years, setYears] = useState(null);
  const [numero, setNumero] = useState(null);
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [Categorys, setCategorys] = useState([]);
  const [categoryChoice, setCategoryChoice] = useState(null);
  const authenticated = useSelector(getAuthentificated);
  const [selectedFile, setSelectedFile] = useState(null);


  const dispatch = useDispatch();

  useEffect(() => { 
    if (authenticated) {
      infoFormConnectedResAPI()
        .then(rep => {
          setYears(rep.data.data.years);
          setCategorys(rep.data.data.categorys);

          dispatch(setData(rep.data.data.rescents.data));
          dispatch(setLinks(rep.data.data.rescents.links));
          dispatch(setPrevPageUrl(rep.data.data.rescents.prev_page_url));
          dispatch(setNextPageUrl(rep.data.data.rescents.next_page_url));
          dispatch(setCurrentPage(rep.data.data.rescents.current_page));
          dispatch(setSizePage(rep.data.data.rescents.per_page));
        })
        .catch(err => {
          console.log(err);
        })
    }

  }, []);


  const categorysOption = Categorys.map((items) => {
    return { value: items.id, label: items.label };
  });

  // const dispatch = useDispatch();
  const handleSubmitAddDocument = () => (event) => {
    setShowSpinnerApp(true);
    event.preventDefault();
    console.log("moment(years).year()", moment(years).year());
    if (categoryChoice && selectedFile) {


      if (selectedFile.type !== 'application/pdf') {
        toastDocarnJuridique("Veuillez choisir un fichier .PDF", settings.toastDuration, "info");
        setShowSpinnerApp(false);
      } else {

        let data = new FormData();
        data.append('file', selectedFile);
        data.append('category_id', categoryChoice.value);
        data.append('publication', moment(years).year());
        data.append('datepublication', years);
        data.append('number', numero);
        data.append('title', title);
        data.append('description', description);

        addDocumentResAPI(data)
          .then((rep) => {
            if (rep.data.response.code === settings.code_success && rep.data.status)  {
              toastDocarnJuridique(rep.data.response.message, settings.toastDuration, "success");
              setSelectedFile(null);
              setYears(null);
              setNumero(null);
              setTitle(null);
              setDescription(null);
            } else {
              toastDocarnJuridique(rep.data.response.message, settings.toastDuration, "error");
            }
            setShowSpinnerApp(false);
          })
          .catch((error) => {
            toastDocarnJuridique(error.response.data.message, settings.toastDuration, "error");
            setShowSpinnerApp(false);
          });
      }
    } else {
      toastDocarnJuridique("Veuillez remplir tous les champs puis choisir un fichier .PDF", 5, "info");
      setShowSpinnerApp(false);
    }
  
  };

  return (
    <div>
      <div class="element-wrapper">
        <div class="element-box">
          <form
          onSubmit={handleSubmitAddDocument()}
            method="post"
            enctype="multipart/form-data"
          >
            <h5 class="form-header">Enregistrement des actes </h5>

            <div class="form-buttons-w">
              {
                showSpinnerApp
                ?
                <SpinnerApp visible={true} />
                :
                <button class="btn btn-primary"> Enregistrer <i class="fa fa-save"></i></button>
              }
						
						<br/><br/>
					  </div>

            <div class="row">
              <div class="col-sm-3">
                <div class="form-group">
                  <label>Nature</label>
                  <Select
                      placeholder="Catégorie"
                      onChange={setCategoryChoice}
                      value={categoryChoice}
                      options={categorysOption}
                    />
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label>Année</label>
                  <input required
                    class="form-control"
                    type="date"
                    value={years}
                    onChange={(e) => setYears(e.target.value)}
                  />
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label>Numéro</label>
                  <input required
                    class="form-control"
                    type="text"
                    value={numero}
                    onChange={(e) => setNumero(e.target.value)}
                  />
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label>Fichier</label>
                  <input required class="form-control" type="file"  onChange={(event) => setSelectedFile(event.target.files[0])} />
                </div>
              </div>
            </div>
            <div id="" class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for=""> Titre</label>
                  <textarea
                  maxLength={255}
                    name="titre" required
                    class="form-control newLocal_30"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for=""> Description</label>
                  <textarea required
                    name="description"
                    value={description}
                    class="form-control newLocal_30"
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

    </div>
  );
};

export default Documentation;
