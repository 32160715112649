// import React, { useEffect } from "react";
import { Switch } from "react-router-dom";
import { BrowserRouter, Route } from "react-router-dom";
// import { createStructuredSelector } from "reselect";
// import { connect } from "react-redux";


// import { selectUserAuthentication } from "./store/reducers/user/user.selectors";
// import { requestAuthenticationAsync } from "./store/reducers/user/user.actions";
import Download from "./pages/Download";
import Favory from "./pages/Favory";
import Welcome from "./pages/Welcome";
import Home from "./pages/Home";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AccountActive from "./pages/AccountActive";
import ResetPassword from "./pages/ResetPassword";
import About from "./pages/About";
import AdminGestUsers from "./pages/AdminGestUsers";
import AdminDocumentation from "./pages/AdminDocumentation";


const App = () => {
  return (
    <BrowserRouter>
      {/** déinition des route */}
      <Switch>
        <Route name="root" exact path={'/home'} component={Home} />  {/** route index de laplication */}
        <Route name="welcome" exact path={'/'} component={Welcome} /> 
        <Route name="about" exact path={'/about'} component={About} /> 
        <Route name="favory" exact path={'/favory'} component={Favory} />
        <Route name="download" exact path={'/download'} component={Download} />
        <Route name="users" exact path={'/users'} component={AdminGestUsers} />
        <Route name="documentation" exact path={'/documentation'} component={AdminDocumentation} />
        <Route name="accountactive" exact path={"/accountactive"} component={AccountActive} />
        <Route name="resetpassword" exact path={"/resetpassword"} component={ResetPassword} />
        <Route exact path={'*'} component={Home} />  {/** En cas d'autres saisie, renvoyer vers l'accueil */}
      </Switch>
      <ToastContainer />
    </BrowserRouter>
  );
};

export default App;