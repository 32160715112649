import React, { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
import logo from '../../../assets/img/logo.png';
import settings, { returnObject, toastDocarnJuridique } from '../../../config/settings';
import SpinnerApp from '../../spinner/SpinnerApp';
import Select from 'react-select'
import ErrorMessage from '../../body_contents/ErrorMessage';
import { configureSettingResAPI, getActorsResAPI, registerResAPI } from '../../../api/docarnJuridiqueApi';
import { NavLink } from 'react-router-dom/cjs/react-router-dom';
import "../../../pages/style.css";
import { useSelector } from 'react-redux';
import { getDataUser } from '../../../store/user/UserSlice';

const SettingsModal = ({data}) => {

  const [duration, setDuration] = useState(0);
  const [Actors, setActors] = useState([]);

  const [showSpinnerApp, setShowSpinnerApp] = useState(false);
  
  const [nameUser, setNameUser] = useState("");
  const [emailUser, setEmailUser] = useState("");
  const [phoneUser, setPhoneUser] = useState("");
  const [addressUser, setAddressUser] = useState("");
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const [message, setMessage] = useState("");

  //gestion des messages d'erreur
  
  const [nameUserMsg, setNameUserMsg] = useState("");
  const [emailUserMsg, setEmailUserMsg] = useState("");
  const [phoneUserMsg, setPhoneUserMsg] = useState("");
  
  const dataUser = useSelector(getDataUser);

  useEffect(() => {
    getActorsResAPI()
      .then(rep => {
        setActors(rep.data.data)
      })
      .catch(err => {
        console.log(err);
      })
  }, []);
  const [categoryChoice, setCategoryChoice] = useState(null);
  const [categoryChoiceMsg, setCategoryChoiceMsg] = useState("");
  const categoryOption = Actors.map((items) => {
    return { value: items.id, label: items.label };
  });

  const [roleChoice, setRoleChoice] = useState(null);
  const [roleChoiceMsg, setRoleChoiceMsg] = useState("")
  const [roleOption, setRoleOption] = useState({});

  useEffect(() => {
    if(categoryChoice && categoryChoice.value){
      const Roles = returnObject(Actors, "id", "roles", categoryChoice.value);
      const options = Roles.map((items) => {
        return { value: items.id, label: items.label };
      });
      if (options.length > 0) {
        setRoleChoice(options[0]);
      } else {
        setRoleChoice(null);
      }
      setRoleOption(options);
    }   
  }, [categoryChoice]);

  //recupération des champ de saisis de l'utilisateur
  

  

  // reinitialisation des meesage d'eereur en cas de nouvelle saisie
  useEffect(() => {
    setCategoryChoiceMsg("");
  }, [categoryChoice]);
  useEffect(() => {
    setRoleChoiceMsg("");
  }, [roleChoice]);




  const handleSubmitRegister = () => (event) => {
    setShowSpinnerApp(true);
    event.preventDefault();

    // const self = this;
    if (categoryChoice !== null && roleChoice !== null) {

      const dataEdit = {
        id: dataUser.id,
        role_id: roleChoice.value,
        actor_id: categoryChoice.value,
        email: dataUser.email,
        duration: duration,
        name: dataUser.name,
        phone: dataUser.phone,
        state: dataUser.state,
        actor: dataUser.actor,
        accounts: dataUser.accounts
      };

      configureSettingResAPI(dataEdit)
        .then(rep => {
          if (rep.data.response.code === settings.code_success && rep.data.status) {
            toastDocarnJuridique(rep.data.response.message, settings.toastDuration + 5, "success");
            // setRegisterSuccess(true);
            // setMessage(rep.data.response.message)
          } else {
            // rep.data.data.category ? setCategoryChoiceMsg(rep.data.data.category) : setCategoryChoiceMsg("");
            // //----------------------------
            // rep.data.data.name ? setNameUserMsg(rep.data.data.name) : setNameUserMsg("");
            // //----------------------------
            // rep.data.data.email ? setEmailUserMsg(rep.data.data.email) : setEmailUserMsg("");
            // //----------------------------
            // rep.data.data.phone ? setPhoneUserMsg(rep.data.data.phone) : setPhoneUserMsg("");

            // toastDocarnJuridique(rep.data.response.message, settings.toastDuration + 5, "error");
          }
          setShowSpinnerApp(false);

        })
        .catch(err => {
          console.log(err);
          toastDocarnJuridique("Erreur survenue. Veuillez controller votre connexion internet ou contactez l'administrateur si le problème persiste.", settings.toastDuration, "error");
          setShowSpinnerApp(false);

          // setCheckCniIsValide(false);

        });
    } else {
      if (categoryChoice == null)
      setCategoryChoiceMsg("Champ obligatoire");
      if (roleChoice == null)
      setRoleChoiceMsg("Champ obligatoire");

      setShowSpinnerApp(false);
      toastDocarnJuridique("Sélectionnez les champs obligatoires", settings.toastDuration, "error");
    }
  }

  return (
    <div aria-hidden="true" className="onboarding-modal modal fade animated" id="SettingsModal" role="dialog">
      <div className="modal-dialog modal-centered" role="document">
        <div className="modal-content text-center">
          <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span className="close-label">Fermer <i className="os-icon os-icon-close"></i></span></button>
          <div className="onboarding-media">
            <img alt="" src={logo} width="40%" />
          </div>

          {
            !registerSuccess
              ?
              <div className="onboarding-content with-gradient">
                <h4 className="onboarding-title">
                  Configuration du compte de { dataUser && dataUser.name }
                </h4>
                <form onSubmit={handleSubmitRegister()} >
                  <div className="onboarding-text">
                    <div className="form-group">
                      <label>Identité</label>
                      <Select placeholder="Sélection votre statut" onChange={setCategoryChoice} value={categoryChoice} options={categoryOption} />
                      <ErrorMessage label={categoryChoiceMsg} />
                    </div>
                    <div className="form-group">
                      <label>Catégorie</label>
                      <Select placeholder="Sélection votre statut" onChange={setRoleChoice} value={roleChoice} options={roleOption} />
                      <ErrorMessage label={roleChoiceMsg} />
                    </div>
                    <div className="form-group">
                      <label>Durée en mois</label>
                      <input required onChange={(e) => setDuration(e.target.value)} className="form-control" placeholder="Durrée en mois" min={0} type="number" />
                      <ErrorMessage label={nameUserMsg} />
                    </div>
                    {
                      !showSpinnerApp
                        ?
                        <div className="buttons-w">
                          <button className="btn btn-primary" >Enregistrer</button> &nbsp;&nbsp;&nbsp;
                        </div>
                        :
                        <SpinnerApp visible={showSpinnerApp} />
                    }

                  </div>
                </form>
              </div>
              :
              <>
              <br/>
                <br/>
                <h1 className="stylefa_success">
                  <i className="fa fa-check-circle"></i>
                </h1>
                <h6 className="stylefa_success"> {message}</h6>
                <br/>
                <br/>
              </>
          }


        </div>
      </div>
    </div>
  );
};

export default SettingsModal;