import React from "react";
import Spinner from 'react-spinner-material';

const SpinnerApp = (prop) => {
  return (
    <>
         <Spinner size={10} spinnerColor={"#47036e"} spinnerWidth={2} visible={prop.visible} />
      {/* {prop.visible && <img src={loader} width="40" height="40" alt="" />} */}
    </>
  );
};

export default SpinnerApp;
