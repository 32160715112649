import { createSlice } from "@reduxjs/toolkit";

export const ConfigurationSlice = createSlice({
  name: "configuration",
  initialState: {
    modeList: true,
    modeDark: false,

  },
  reducers: {
    setModeList: (state,action) => {
      console.log("seModeList", action.payload);
      state.modeList = action.payload;
    },
    setModeDark: (state,action) => {
      state.modeDark = action.payload;
    }
  }
});

export const { setModeList,  setModeDark } = ConfigurationSlice.actions;
export const getModeList = (state) => state.configuration.modeList;
export const getModeDark = (state) => state.configuration.modeDark;
export default ConfigurationSlice.reducer;
