import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getModeList,
  setModeList,
} from "../../store/configuration/ConfigurationSlice";
import "./AddUser.css";

const AddDuration = () => {
  const modeList = useSelector(getModeList);
  const dispatch = useDispatch();

  const setMode = (mode) => {
    dispatch(setModeList(mode));
  };
  return (
    <>
      {/* <div   data-target="#RegisterModalAdmin" data-toggle="modal" className="floated-colors-btn second-floated-btn"> */}
      {/* <div className="floated-colors-btn second-floated-btn">
        <div className="os-toggler-w">
          <i className="fa fa-clock-o"> 16-11-2024 </i>
        </div>
      </div> */}
    </>
  );
};

export default AddDuration;
