import React, { useEffect, useState } from "react";
import ItemDocument from "./contents/ItemDocument";
import Select from "react-select";
import settings, { toastDocarnJuridique } from "../../config/settings";
import SpinnerApp from "../spinner/SpinnerApp";
import SearchLoader from "../Loaders/SearchLoader/SearchLoader.loader";
import { infoFormConnectedResAPI, infoFormResAPI, searchResAPI } from "../../api/docarnJuridiqueApi";
import { getModeList } from "../../store/configuration/ConfigurationSlice";
import { useSelector } from "react-redux";
import TrLineHome from "./contents/TrLineHome";
import { getAuthentificated, selectCurrentUser } from "../../store/user/UserSlice";
import PaginationComponent from "../pagination/PaginationComponent";
import { useDispatch } from "react-redux";
import { getData, setCurrentPage, setData, setLinks, setNextPageUrl, setPrevPageUrl, setSizePage } from "../../store/page/PageSlice";
import API from "../../api/apiKit";
import "./style.css"
import { useLocation } from "react-router-dom";
import { getCategoryState, getSearchState, getYearState } from "../../store/search/SearchSlice";
import emptyLogo from "../../assets/img/Generic-Purple-Folder-icon.png";

const SearchDocuments = ({handleAddFavory, handleSubmitDownloadFile}) => {
  const [showSpinnerApp, setShowSpinnerApp] = useState(false);
  const [Years, setYears] = useState([]);
  const [userId, setUserId] = useState(1);
  const [accountId, setAccountId] = useState(1);
  const [Categorys, setCategorys] = useState([]);
  const [yearChoice, setYearChoice] = useState(null);
  const [categoryChoice, setCategoryChoice] = useState(null);
  const [search, setSearch] = useState("");
  const [modeLoading, setModeLoading] = useState(true);
  const [baseUri, setBaseUri] = useState("listDocument");
  const authenticated = useSelector(getAuthentificated);
  const data = useSelector(getData);
  const currentUser = useSelector(selectCurrentUser);

  const searchState = useSelector(getSearchState);
  const yearState = useSelector(getYearState);
  const categoryState = useSelector(getCategoryState);
  
  const modeList = useSelector(getModeList);

  let location = useLocation();

  const dispatch = useDispatch();

  useEffect(() => { 
    if (authenticated) {

      setUserId(currentUser.id);
      setBaseUri("listDocumentConnected");
      infoFormConnectedResAPI()
        .then(rep => {
          setYears(rep.data.data.years);
          setCategorys(rep.data.data.categorys);

          dispatch(setData(rep.data.data.rescents.data));
          dispatch(setLinks(rep.data.data.rescents.links));
          dispatch(setPrevPageUrl(rep.data.data.rescents.prev_page_url));
          dispatch(setNextPageUrl(rep.data.data.rescents.next_page_url));
          dispatch(setCurrentPage(rep.data.data.rescents.current_page));
          dispatch(setSizePage(rep.data.data.rescents.per_page));

          setModeLoading(false);
        })
        .catch(err => {
          console.log(err);
        })
    } else {

      setBaseUri("listDocument");
      infoFormResAPI()
        .then(rep => {
          setYears(rep.data.data.years);
          setCategorys(rep.data.data.categorys);
          
          dispatch(setData(rep.data.data.rescents.data));
          dispatch(setLinks(rep.data.data.rescents.links));
          dispatch(setPrevPageUrl(rep.data.data.rescents.prev_page_url));
          dispatch(setNextPageUrl(rep.data.data.rescents.next_page_url));
          dispatch(setCurrentPage(rep.data.data.rescents.current_page));
          dispatch(setSizePage(rep.data.data.rescents.per_page));
          
          setModeLoading(false);
        })
        .catch(err => {
          console.log(err);
        })
    }

  }, []);


  useEffect(() => {
    setSearch(searchState);
    if (searchState != null ) {
      
      setShowSpinnerApp(true);
      setModeLoading(true);

      const yearVal = yearState !== null ? yearState : "";
    const categoryVal = categoryState !== null ? categoryState : "";
      
    searchResAPI({ year: yearVal, category: categoryVal, search: searchState, userId: userId })
      .then((rep) => {;
      
        dispatch(setData(rep.data.data.data));
        dispatch(setLinks(rep.data.data.links));
        dispatch(setPrevPageUrl(rep.data.data.prevPageUrl));
        dispatch(setNextPageUrl(rep.data.data.nextPageUrl));
        dispatch(setCurrentPage(rep.data.data.currentPage));
        dispatch(setSizePage(rep.data.data.per_page));

        setShowSpinnerApp(false);
        setModeLoading(false);
      })
      .catch((error) => {
        toastDocarnJuridique(error.response.data.message, settings.toastDuration, "error");

        setShowSpinnerApp(false);
      });
    }
  }, [searchState]);

  const yearsOption = Years.map((items) => {
    return { value: items.publication, label: items.publication };
  });
  //On ajoute l'élément en début du ta
  yearsOption.unshift({ value: "", label: "Toutes" });
  const categorysOption = Categorys.map((items) => {
    return { value: items.label, label: items.label };
  });
  categorysOption.unshift({ value: "", label: "Toutes" });

  const getSearch = (e) => {
    setSearch(e.target.value);
  };
  // const dispatch = useDispatch();
  const handleSubmitSearch = () => (event) => {
    // dispatch(DisplayModeDark(false));
    setShowSpinnerApp(true);
    setModeLoading(true);
    event.preventDefault();
    
    const yearVal = yearChoice !== null ? yearChoice.value : "";
    const categoryVal = categoryChoice !== null ? categoryChoice.value : "";

    // console.log(yearVal, categoryVal, search);
    searchResAPI({ year: yearVal, category: categoryVal, search: search, userId: userId, accountId: accountId })
      .then((rep) => {;
      
        dispatch(setData(rep.data.data.data));
        dispatch(setLinks(rep.data.data.links));
        dispatch(setPrevPageUrl(rep.data.data.prevPageUrl));
        dispatch(setNextPageUrl(rep.data.data.nextPageUrl));
        dispatch(setCurrentPage(rep.data.data.currentPage));
        dispatch(setSizePage(rep.data.data.per_page));

        setShowSpinnerApp(false);
        setModeLoading(false);
      })
      .catch((error) => {
        toastDocarnJuridique(error.response.data.message, settings.toastDuration, "error");

        setShowSpinnerApp(false);
      });
  };
  // if (data.length === 0) data = initialData;
  

  const handleChangePage = (uri = "") => {
  
    const yearVal = yearChoice !== null ? yearChoice.value : "";
    const categoryVal = categoryChoice !== null ? categoryChoice.value : "";

    if (uri) {
      // setModeLoading(true);
      return new Promise((resolve, reject) => {
            API()
            .post("searchfiles" + uri.replace(/\//g, ""), { year: yearVal, category: categoryVal, search: search })
            .then((rep) => {
              // console.log("rep.data.data.data", rep.data.data);
              dispatch(setData(rep.data.data.data));
              dispatch(setLinks(rep.data.data.links));
              dispatch(setPrevPageUrl(rep.data.data.prev_page_url));
              dispatch(setNextPageUrl(rep.data.data.next_page_url));
              dispatch(setCurrentPage(rep.data.data.current_page));
              dispatch(setSizePage(rep.data.data.per_page));
              
              // setModeLoading(false);

            }).catch((error) => reject(error));
        });
    }
  }

  const itemsList = data.map((items, index) => { 
    return <ItemDocument handleSubmitDownloadFile={handleSubmitDownloadFile} handleAddFavory={handleAddFavory} index={index} key={index} document={items} />;
  });

  const itemsTable = data.map((items, index) => {
    return <TrLineHome handleSubmitDownloadFile={handleSubmitDownloadFile} handleAddFavory={handleAddFavory} index={index} key={index} document={items} />;
  });

  
  const previewPage = () => {
    
  }

  const nextPage = () => {
      
  }

  const paginateConfig = {
    data: [],
    numbers: 15,
    currentPage: 1,
    urlPagination: '',
    previewPage: previewPage,
    nextPage: nextPage,
  }
  return (
    <div>
      <div className="row">
        <div className="col-sm-12">
          <div className="element-wrapper">
            
              <div className="row">
                {/* <div className="col-xs- col-2 col-sm-4 col-xxxl-3"> */}
                <div className="col-xs-6 col-sm-2 col-xxxl-6">
                  <label></label>
                  <div className="  ">
                    <Select
                      placeholder="Année"
                      onChange={setYearChoice}
                      value={yearChoice}
                      options={yearsOption}
                    />
                  </div>
                </div>
                <div className="col-xs-6 col-sm-2 col-xxxl-6">
                  <label></label>
                  <div className=" ">
                    <Select
                      placeholder="Catégorie"
                      onChange={setCategoryChoice}
                      value={categoryChoice}
                      options={categorysOption}
                    />
                  </div>
                </div>
                <div className="col-sm-8 col-xxxl-12">
                <form onSubmit={handleSubmitSearch()}>
                  <label></label>
                  <div className="input-group">
                    <input
                      onChange={getSearch}
                      value={search}
                      className="text-docarn form-control rounded"
                      placeholder="Saisir l'élément à rechercher"
                      type="text"
                    />
                    <div className="input-group-btn">
                      {showSpinnerApp === true ? (
                        <SpinnerApp visible={showSpinnerApp} />
                      ) : (
                        <a href="/#" onClick={handleSubmitSearch()}>
                          <i className="styleSearch newLocal_4 icon-w fa fa-search"></i>
                        </a>
                      )}
                    </div>
                  </div>
                  </form>
                </div>
              </div>
            
            <div className="row newLocal_0">
              {
                data.length === 0
                ?
                <img className="user-name newLocal_20" src={emptyLogo} alt="" width="100%" />
                :
                <div className="col-10">
                {modeLoading === true ? (
                  <SearchLoader />
                ) : modeList === true ? (
                  itemsList
                ) : (
                  <>
                    <div className="user-name newLocal_1">
                      <div className="table-responsive">
                        <table id="dataTable" className="table table-striped table-lightfont">
                          <thead>
                            <tr>
                              <th>TITRE</th>
                              <th>#</th>
                            </tr>
                          </thead>
                          <tfoot>
                            <tr>
                              <th>TITRE</th>
                              <th>#</th>
                            </tr>
                          </tfoot>
                          <tbody>
                            {itemsTable}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                  // <TableDocument  handleSubmitDownloadFile={handleSubmitDownloadFile} handleAddFavory={handleAddFavory} document={data} />
                )}
                <PaginationComponent setModeLoading = {setModeLoading} handleChangePage = {handleChangePage} />
              </div>
              }
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchDocuments;
