import React from "react";
import logo from "../assets/img/logo.png";
import ItemMenu from "./header_content/ItemMenu";
import AvatarUser from "./userdata/AvatarUser";
import { selectCurrentUser } from "../store/user/UserSlice";
import { useSelector } from "react-redux";

const Header = (ppte) => {
  const account = useSelector(selectCurrentUser)
  return (
    <>
      <div className="all-wrapper solid-bg-all">
        <div className="menu-mobile color-scheme-dark">
          <div className="mm-logo-buttons-w">
            <a className="mm-logo" href="/#">
              <img alt="" src={ logo } />
              <span>{ account && account.user && account.user.name }</span>
            </a>
            <div className="mm-buttons">
              <div className="content-panel-open">
                <div className="os-icon os-icon-grid-circles"></div>
              </div>
              <div className="mobile-menu-trigger">
                <div className="os-icon os-icon-hamburger-menu-1"></div>
              </div>
            </div>
          </div>
          <div className="menu-and-user">
            <ul className="main-menu">
              <ItemMenu page={ppte.page} />
            </ul>
          </div>
        </div>
        <div className="menu-w color-scheme-light color-style-default menu-position-top menu-layout-compact sub-menu-style-over sub-menu-color-bright selected-menu-color-bright menu-activated-on-hover">
          <div>
            <img alt="" src={logo} width="20%" />
          </div>
          <ul className="main-menu">
            <ItemMenu page={ppte.page} />
            <li className="">
              <AvatarUser />
            </li>
          </ul>
        </div>
        
      </div>
    </>
  );
};
//  createStructuredSelector({
//   authenticated: selectUserAuthentication
// });

export default Header;
