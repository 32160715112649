import React from "react";
import { useSelector } from "react-redux";
import { getAuthentificated } from "../../../store/user/UserSlice";
import SpinnerApp from "../../spinner/SpinnerApp";
import "./Item.style.css";
import LoaderPage from "./LoaderPage";
import { getIsLoader } from "../../../store/page/PageSlice";

const ItemFavory = (prop) => {
  const authenticated = useSelector(getAuthentificated);
  const isLoader = useSelector(getIsLoader);
  const Documents = prop.document.document;
  const Index = prop.index;

  return (
    <div className="user-name newLocal_1">
      {
      isLoader && <LoaderPage message={"Action en cours..."} />
    }
      <h6 className="user-title onboarding-title">
        {/* <strong> JO-CAMEROUN N°22 du 2010-12-01  &nbsp; <i className="fa fa-star"></i></strong> */}
        {authenticated === true ? (
          <>
            <strong
              className="pointer Item_style"
              onClick={() => prop.handleSubmitDownloadFile(Documents, Index)}
            >
              {Documents.category}&nbsp;N°{Documents.number} &nbsp;du &nbsp;
              {Documents.datepublication}
            </strong>
            
            &nbsp;&nbsp;
              <i
                className="pointer OnFavory fa fa-star"
                onClick={() => prop.handleAddFavory(Documents, false, Index)}
              ></i>
            </>
        ) : (
          <strong>
            {Documents.category}&nbsp;N°{Documents.number} &nbsp;du &nbsp;
            {Documents.datepublication}
          </strong>
        )}
      </h6>
      <div className="">{Documents.description}</div>
    </div>
  );
};

export default ItemFavory;
