import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getModeList,
  setModeList,
} from "../../store/configuration/ConfigurationSlice";
import "./AddUser.css";
import RegisterModalAdmin from "../auth/RegisterModalAdmin";

const AddUser = () => {
  const modeList = useSelector(getModeList);
  const dispatch = useDispatch();

  const setMode = (mode) => {
    dispatch(setModeList(mode));
  };
  return (
    <>
    <RegisterModalAdmin />
      <div   data-target="#RegisterModalAdmin" data-toggle="modal" className="floated-colors-btn second-floated-btn">
        <div className="os-toggler-w">
          <i className="fa fa-user-plus"></i>
        </div>
      </div>
    </>
  );
};

export default AddUser;
