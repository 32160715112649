import { toast } from "react-toastify";

const settings = {
    baseUrl: window.env.BASE_URL_APP,
    baseUrlAPI: window.env.BASE_URL_API,
    // baseUrlAPI: 'http://jur-api.docarn.com/public/api',
    DocarnUrlSite: window.env.BASE_URL_DOCARN,
    client: window.env.CLIENT,
    code_success: 200,
    code_empty: 404,
    code_error: 500,
    telApplication: window.env.TEL_APP,
    copyright : window.env.COPYRIGHT,
    emailApp : window.env.EMAIL_APP,
    localStorageName: window.env.LOCAL_STORAGE_NAME,
    toastDuration: window.env.TOAST_DURATION, //5 secondes
    devMode: window.env.DEV_MODE, //Mode de dévéloppement (sert à affichier les consome ou pas)
    idAdmin: window.env.ID_ADMIN 
};

export default settings;

export const toastDocarnJuridique = (text, duration = settings.toastDuration, type) => {

    switch (type) {
        case "success":
        toast.success( text , {
            position: "top-right",
            autoClose: duration * 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        break;

        case "error":
        toast.error( text , {
            position: "top-right",
            autoClose: duration * 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        break;

        case "info":
        toast.info( text , {
            position: "top-right",
            autoClose: duration * 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        break;

        case "warning":
        toast.war( text , {
            position: "top-right",
            autoClose: duration * 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        break;
    
        default:{
        toast( text , {
            position: "top-right",
            autoClose: duration * 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        }
    }
    
}

export const modifiedObjectArray = (array, indexArray, indexCompare, newValue) => {

    let newArray = [];
    for (let index = 0; index < array.length; index++) {
      let element = array[index];
      let elementPush = {};
  
      if (element[indexArray] === indexCompare) {
        elementPush = newValue;
      } else {
        elementPush = element;
      }
      newArray.push(elementPush);
  
    }
  
    return newArray;
  };

  export const returnObject = (array, indexArray, indexReturn, indexCompare) => {
  
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
  
      if (element[indexArray] === indexCompare) {
        return element[indexReturn];
      }
      
    }
  };