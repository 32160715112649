import React from "react";
import "./Item.style.css";

const LoaderPage = ({message}) => {

  return (
    <>
    <div className="loader-overlay">
      
      <div className="loader-text"> { message }</div>
    </div>
    </>
    
  );
  }

export default LoaderPage;
