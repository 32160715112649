import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { loginResAPI, initResetPasswordResAPI } from '../../api/docarnJuridiqueApi';
// import { useSelector } from 'react-redux';
import logo from '../../assets/img/logo.png';
import settings, { toastDocarnJuridique } from '../../config/settings';
import { setAuthentificated, setAuthentificatedAdmin, setUser } from '../../store/user/UserSlice';
import { setToken } from '../../utils/localstorage';
import SpinnerApp from '../spinner/SpinnerApp';

const LoginModal = () => {

  const [showSpinnerApp, setShowSpinnerApp] = useState(false);
  const [loginUser, setLoginUser] = useState("");
  const [passwordUser, setPasswordUser] = useState("");
  const [modeLogin, setModeLogin] = useState(true);

  const [resetSuccess, setResetSuccess] = useState(false);
  const [messageReset, setMessageRest] = useState("");
  const dispatch = useDispatch();

  const getLoginUser = (e) => {
    setLoginUser(e.target.value);
  }

  const getPasswordUser = (e) => {
    setPasswordUser(e.target.value);
  }

  const changeModeLogin = () => {
    setModeLogin(!modeLogin);
  }
  const handleSubmitLogin = () => (e) => {
    e.preventDefault();
    setShowSpinnerApp(true);

    loginResAPI({ login: loginUser, password: passwordUser })
      .then(rep => {
        if (rep.data.response.code === settings.code_success && rep.data.status) {
          toastDocarnJuridique(rep.data.response.message, settings.toastDuration, "success");
          setToken(settings.localStorageName, rep.data.data.token);
          dispatch(setAuthentificated(true));
          if (rep.data.data.account.user.actor_id === settings.idAdmin) {
            dispatch(setAuthentificatedAdmin(true));
          }
          dispatch(setUser(rep.data.data.account));
          document.location.href="home"
          // window.location.reload(); authentificatedAdmin
        } else {
          toastDocarnJuridique(rep.data.response.message, settings.toastDuration, "error");
        }
        setShowSpinnerApp(false);

      })
      .catch(err => {
        toastDocarnJuridique("Erreur rencontrée. reéssayez ou revenez une autre fois", settings.toastDuration, "error");
        setShowSpinnerApp(false);
        // setCheckCniIsValide(false);

      })
  }

  const handleSubmitResetPassword = () => (e) => {
    e.preventDefault();
    setShowSpinnerApp(true);

    // const self = this;
    initResetPasswordResAPI({ login: loginUser })
      .then(rep => {
        if (rep.data.response.code === settings.code_success && rep.data.status) {
          toastDocarnJuridique(rep.data.response.message, settings.toastDuration + 10, "success");
          // setModeLogin(true);
          setResetSuccess(true);
          setMessageRest(rep.data.response.message);
        } else {
          toastDocarnJuridique(rep.data.response.message, settings.toastDuration, "error");
        }
        setShowSpinnerApp(false);

      })
      .catch(err => {

        console.log(err);
        setShowSpinnerApp(false);
        toastDocarnJuridique("Erreur rencontrée. reéssayez ou revenez une autre fois", "error");
        // setCheckCniIsValide(false);

      })
  }
  return (
    <>
      <div aria-hidden="true" className="onboarding-modal modal fade animated " id="LoginModal" role="dialog">
        <div className="modal-dialog modal-centered" role="document">
          <div className="modal-content text-center modal-centered">
            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span className="close-label">Fermer <i className="os-icon os-icon-close"></i></span></button>
            <div className="onboarding-media">
              <img alt="" src={logo} width="40%" />
            </div>
            <div className="onboarding-content with-gradient">

              {
                modeLogin === true
                  ?
                  <>
                    <h4 className="onboarding-title">
                      Connexion

                    </h4>
                    <form onSubmit={handleSubmitLogin()}>
                      <div className="onboarding-text">
                        <div className="form-group">
                          {
                            showSpinnerApp
                              ?
                              <input className="form-control" disabled value={loginUser} onChange={getLoginUser} required placeholder="Login" type="text" />
                              :
                              <input className="form-control" value={loginUser} onChange={getLoginUser} required placeholder="Login" type="text" />
                          }
                        </div>
                        <div className="form-group">
                          {
                            showSpinnerApp
                              ?
                              <input className="form-control" value={passwordUser} disabled onChange={getPasswordUser} required placeholder="Mot de passe" type="password" />
                              :
                              <input className="form-control" onChange={getPasswordUser} required placeholder="Mot de passe" type="password" />}

                        </div>
                        <div className="buttons-w">
                          {
                            showSpinnerApp
                              ?
                              <SpinnerApp visible={showSpinnerApp} />
                              :
                              <>
                                <button className="btn btn-primary">Se connecter</button> &nbsp;&nbsp;&nbsp;
                                <SpinnerApp visible={showSpinnerApp} />  <strong className="pointer" onClick={changeModeLogin}> Mot de passe oublié?</strong>
                              </>
                          }


                        </div>

                      </div>
                    </form>
                  </>
                  :
                  <>
                    {
                      !resetSuccess
                        ?
                        <>
                          <h4 className="onboarding-title">
                            Récupération du mot de passe
                          </h4>
                          <form onSubmit={handleSubmitResetPassword()}>
                            <div className="onboarding-text">
                              <div className="form-group">
                                {
                                  showSpinnerApp
                                    ?
                                    <input className="form-control" disabled value={loginUser} onChange={getLoginUser} required placeholder="Login" type="text" />
                                    :
                                    <input className="form-control" value={loginUser} onChange={getLoginUser} required placeholder="Login" type="text" />
                                }
                              </div>
                              <div className="buttons-w">
                                {
                                  showSpinnerApp
                                    ?
                                    <SpinnerApp visible={showSpinnerApp} />
                                    :
                                    <>
                                      <button className="btn btn-primary">Récupération</button> &nbsp;&nbsp;&nbsp;
                                      <strong className="pointer" onClick={changeModeLogin} > Se connecter</strong>
                                    </>
                                }

                              </div>

                            </div>
                          </form>
                        </>
                        :
                        <>
                        <h4 className="onboarding-title">
                            Récupération du mot de passe
                          </h4>
                          <h1 className="stylefa_success">
                            <i className="fa fa-check-circle"></i>
                          </h1>
                          <h6 className="stylefa_success"> {messageReset}</h6>
                          <br />
                          <div className="input-group">
                            <div className="input-group-btn">
                            </div>
                          </div>
                        </>
                    }
                  </>
              }

            </div>
          </div>
        </div>
      </div>
    </>
  );

};

export default LoginModal;