import React from "react";
import PropTypes from 'prop-types';
import { CONSTS } from "../config/constants";
import LoginModal from "./auth/LoginModal";
import RegisterModal from "./auth/RegisterModal";
import DisplayMode from "./body_contents/DisplayMode";
import RescentDocuments from "./body_contents/RescentDocuments";
import SearchDocuments from "./body_contents/SearchDocuments";
import DownloadDocuments from "./body_contents/DownloadDocuments";
import FavoryDocuments from "./body_contents/FavoryDocuments";
import { useDispatch, useSelector } from "react-redux";
import { setFavoryData, setIsLoader, setSateData } from "../store/page/PageSlice";
import { checkDownloadResAPI, downloadResAPI, favoryAddResAPI } from "../api/docarnJuridiqueApi";
import settings, { toastDocarnJuridique } from "../config/settings";
import ListUsers from "./admin/ListUsers";
import AddUser from "./body_contents/AddUser";
import { getAuthentificated, getAuthentificatedAdmin } from "../store/user/UserSlice";
import Documentation from "./admin/Documentation";
import AddDuration from "./body_contents/AddDuration";


const Body = ({ activePage}) => {
  const dispatch = useDispatch();
  const isAdmin = useSelector(getAuthentificatedAdmin);
  const authenticated = useSelector(getAuthentificated);
  const handleAddFavory = (document, onOffFavory, index) => {

    dispatch(setIsLoader(true));
    dispatch(setSateData({state: true, index:index}));
    favoryAddResAPI({ document_id: document.id })
      .then((rep) => { 
        if (rep.data.response.code === settings.code_success) {
          
          dispatch(setFavoryData({favory: onOffFavory, index:index}));
          dispatch(setSateData({state: false, index:index}));
          toastDocarnJuridique(
            rep.data.response.message,
            settings.toastDuration,
            "success"
          );
        }
        dispatch(setIsLoader(false));
      })
      .catch((error) => {
        dispatch(setSateData({state: false, index:index}));
        dispatch(setIsLoader(false));
        toastDocarnJuridique(error, settings.toastDuration, "error");
      });
  };

  const handleSubmitDownloadFile = (Document, index) => {

    dispatch(setIsLoader(true));
    if (index){
      dispatch(setSateData({state: true, index:index}));
    }

    checkDownloadResAPI({ filepath: Document })
      .then((rep) => {

        if (rep.data.response.code === settings.code_success && rep.data.status) {
          downloadResAPI(rep.data.data.filename);
          toastDocarnJuridique(
            rep.data.response.message,
            settings.toastDuration,
            "infos"
          );
        } else {
          toastDocarnJuridique(
            rep.data.response.message,
            settings.toastDuration,
            "error"
          );

        }
        dispatch(setIsLoader(false));
        if (index){
          dispatch(setSateData({state: false, index:index}));
        }
      })
      .catch((error) => {
        if (index){
          dispatch(setSateData({state: false, index:index}));
        }
        
        dispatch(setIsLoader(false));
        console.log("error", error);
        toastDocarnJuridique(error.response.data.message, settings.toastDuration, "error");
      });
  }; 
 
  return (
    <div>
      <div className="content-w">
        <div className="content-i">
          <div className="content-box">
            {activePage === CONSTS._HOME && <SearchDocuments handleAddFavory = {handleAddFavory} handleSubmitDownloadFile = {handleSubmitDownloadFile} />}
            {activePage === CONSTS._FAVORY &&  <FavoryDocuments handleAddFavory = {handleAddFavory} handleSubmitDownloadFile = {handleSubmitDownloadFile} /> }
            {activePage === CONSTS._DOWNLOAD &&  <DownloadDocuments handleAddFavory = {handleAddFavory} handleSubmitDownloadFile = {handleSubmitDownloadFile}  /> }
            {activePage === CONSTS._USERS &&  <ListUsers /> }
            {activePage === CONSTS._DOCUMENTATION &&  <Documentation /> }
            {/* {activePage === CONSTS._DOCUMENTATION &&  <DownloadDocuments handleAddFavory = {handleAddFavory} handleSubmitDownloadFile = {handleSubmitDownloadFile}  /> } */}
            <LoginModal />
            <RegisterModal/>
            {/* <DarkMode /> */}
            <DisplayMode />
            {
              authenticated && <>
                {
                  isAdmin ? <AddUser /> : <AddDuration />
                }
              </>
            }
          </div>
          <RescentDocuments handleSubmitDownloadFile={handleSubmitDownloadFile} />
        </div>
      </div>
    </div>
  );
};

SearchDocuments.defaultProps = {
  activePage: CONSTS._HOME
};

SearchDocuments.propTypes = {
  activePage: PropTypes.string
};



export default Body;