import React from 'react';
import { useSelector } from 'react-redux';
import { getLinks } from '../../store/page/PageSlice';
import "./Item.style.css";

const NumberPage = (prop) => {

    const links = useSelector(getLinks);
    const numbers  = links.length;

    return (
        <>
        {
            numbers > 3
            &&
            <>
              &nbsp;&nbsp;
                {
                    prop.item.active 
                    ?
                    <label className={"OnFavory"}>
                        {prop.item.label !== "pagination.previous" && prop.item.label !== "pagination.next" && prop.item.label}
                    </label>
                    :
                    <label onClick={() => prop.handleChangePage(prop.item.url)} className={"pointer"}>
                        {prop.item.label !== "pagination.previous" && prop.item.label !== "pagination.next" && prop.item.label}
                    </label>
                }
                &nbsp;&nbsp;
            </>
        }
        </>
    );
};

export default NumberPage;