import React from "react";
import Body from "../components/Body";
import Header from "../components/Header";
import { CONSTS } from "../config/constants";

const Home = () => {
  
  return (
    <div>
      <Header page={CONSTS._HOME} />
      <Body activePage={CONSTS._HOME} />
    </div>
  );
};

export default Home;

