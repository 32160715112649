import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getModeList,
  setModeList,
} from "../../store/configuration/ConfigurationSlice";
// import { selectDisplayModeList } from "../../store/reducers/configuration/configuration.selectors";
import "./DisplayMode.css";

const DisplayMode = () => {
  const modeList = useSelector(getModeList);
  const dispatch = useDispatch();

  const setMode = (mode) => {
    dispatch(setModeList(mode));
  };
  return (
    <>
      {modeList === true ? (
        <div className="floated-chat-btn-perso" onClick={() => setMode(false)}>
          <span>
            <i className="fa fa-table"></i>
            &nbsp;Tableau
          </span>
        </div>
      ) : (
        <div className="floated-chat-btn-perso" onClick={() => setMode(true)}>
          <span>
            <i className="fa fa-list"></i>
            &nbsp;Liste
          </span>
        </div>
      )}
    </>
  );
};

export default DisplayMode;
