import React, { useEffect, useState } from "react";
import ItemFavory from "./contents/ItemFavory";
import DownloadLoader from "../Loaders/DownloadLoader/DownloadLoader.loader";
import { useSelector } from "react-redux";
import { getModeList } from "../../store/configuration/ConfigurationSlice";
import TrLineFavory from "./contents/TrLineFavory";
import { favoryListResAPI } from "../../api/docarnJuridiqueApi";
import { useDispatch } from "react-redux";
import { getData, setCurrentPage, setData, setLinks, setNextPageUrl, setPrevPageUrl, setSizePage } from "../../store/page/PageSlice";
import PaginationComponent from "../pagination/PaginationComponent";
import API from "../../api/apiKit";
import emptyLogo from "../../assets/img/Generic-Purple-Folder-icon.png";

const FavoryDocuments = ({handleAddFavory, handleSubmitDownloadFile}) => {

  const modeList = useSelector(getModeList);
  const [modeLoading, setModeLoading] = useState(true);
  const dispatch = useDispatch();
  const data = useSelector(getData);

  useEffect(() => {

    favoryListResAPI()
      .then(rep => {
        
        dispatch(setData(rep.data.data));
        dispatch(setLinks(rep.data.links));
        dispatch(setPrevPageUrl(rep.data.prev_page_url));
        dispatch(setNextPageUrl(rep.data.next_page_url));
        dispatch(setCurrentPage(rep.data.current_page));
        dispatch(setSizePage(rep.data.per_page));

        setModeLoading(false);
      })
      .catch(err => {
        console.log(err);
      })
  }, []);

  const handleChangePage = (uri = "") => {
  
    if (uri) {
      // setModeLoading(true);
      return new Promise((resolve, reject) => {
            API()
            .get("favorys" + uri, {})
            .then((rep) => {
              // console.log("rep.data.data.data", rep.data.data);
              dispatch(setData(rep.data.data.data));
              dispatch(setLinks(rep.data.data.links));
              dispatch(setPrevPageUrl(rep.data.data.prev_page_url));
              dispatch(setNextPageUrl(rep.data.data.next_page_url));
              dispatch(setCurrentPage(rep.data.data.current_page));
              dispatch(setSizePage(rep.data.data.per_page));
              
              // setModeLoading(false);

            }).catch((error) => reject(error));
        });
    }
  }

  
    const itemsList = data.map((items, index) => {
    return <ItemFavory handleSubmitDownloadFile={handleSubmitDownloadFile} handleAddFavory={handleAddFavory} index={index}  key={index} document={items} />;
  });
  
    const itemsTable = data.map((items, index) => {
    return <TrLineFavory handleSubmitDownloadFile={handleSubmitDownloadFile} handleAddFavory={handleAddFavory}  index={index}  key={index} document={items} />;
  });

  return (
    <div>
      <div className="row">
        <div className="col-sm-12">
          <div className="element-wrapper">
            <div className="row newLocal_0">
            {
                data.length === 0
                ?
                <img className="user-name newLocal_20" src={emptyLogo} alt="" width="100%" />
                :
              <div className="col-10">
                {modeLoading === true ? (
                  <DownloadLoader />
                ) : modeList === true ? (  
                  itemsList
                ) : (
                  <>
                    <div className="user-name newLocal_1">
                      <div className="table-responsive">
                          <table id="dataTable" className="table table-striped table-lightfont">
                              <thead>
                                  <tr>
                                      <th>TITRE</th>
                                      <th>#</th>
                                </tr>
                              </thead>
                              <tfoot>
                                <tr>
                                  <th>TITRE</th>
                                  <th>#</th>
                                </tr>
                              </tfoot>
                              <tbody>
                                  { itemsTable }
                              </tbody>
                          </table>
                      </div>
                  </div>
                  </>
                )}
                <PaginationComponent setModeLoading = {setModeLoading} handleChangePage = {handleChangePage} />
              </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FavoryDocuments;
