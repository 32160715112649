import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import { CONSTS } from "../../config/constants";
import settings from "../../config/settings";
import {
  getAuthentificated,
  getAuthentificatedAdmin,
  logout,
} from "../../store/user/UserSlice";

const ItemMenu = (ppte) => {
  const authenticated = useSelector(getAuthentificated);
  const isAdmin = useSelector(getAuthentificatedAdmin);
  const dispatch = useDispatch();
  // console.log(authenticated);
  const styleHome = ppte.page === CONSTS._HOME ? "selected" : "";
  const styleDownload = ppte.page === CONSTS._DOWNLOAD ? "selected" : "";
  const styleFavory = ppte.page === CONSTS._FAVORY ? "selected" : "";
  const styleUser = ppte.page === CONSTS._USERS ? "selected" : "";
  const styleDocumentation = ppte.page === CONSTS._DOCUMENTATION ? "selected" : "";
  return (
    <>
      <li className={styleHome}>
        <NavLink to="/home">
          <div className="icon-w">
            <div className="fa fa-home"></div>
          </div>
          <span>Documentation</span>
        </NavLink>
      </li>
      {authenticated === true ? (
        <>
          {isAdmin && (
            <li className={styleUser}>
              <NavLink to="/users">
                <div className="icon-w">
                  <div className="fa fa-users"></div>
                </div>
                <span>Utilisateurs</span>
              </NavLink>
            </li>
          )}
          {isAdmin && (
            <li className={styleDocumentation}>
              <NavLink to="/documentation">
                <div className="icon-w">
                  <div className="fa fa-folder"></div>
                </div>
                <span>Enregistrement</span>
              </NavLink>
            </li>
          )}
          <li className={styleDownload}>
            <NavLink to="/download">
              <div className="icon-w">
                <div className="fa fa-download"></div>
              </div>
              <span>Téléchargements</span>
            </NavLink>
          </li>
          <li className={styleFavory}>
            <NavLink to="/favory">
              <div className="icon-w">
                <div className="fa fa-star"></div>
              </div>
              <span>Favoris</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/home"  onClick={() => dispatch(logout())}>
              <div className="icon-w">
                <div className="fa fa-sign-out"></div>
              </div>
              <span>Déconnexion</span>
            </NavLink>
          </li>
        </>
      ) : (
        <>
          <li className="">
            <a href="/" data-target="#LoginModal" data-toggle="modal">
              <div className="icon-w">
                <div className="fa fa-sign-in"></div>
              </div>
              <span>Se connecter</span>
            </a>
          </li>
          <li className="">
            <a href="/#" data-target="#RegisterModal" data-toggle="modal">
              <div className="icon-w">
                <div className="fa fa-user-plus"></div>
              </div>
              <span>Créer un compte</span>
            </a>
          </li>
          <li className="">
            <NavLink to="/about">
              <div className="icon-w">
                <div className="fa fa-external-link"></div>
              </div>
              <span>A propos</span>
            </NavLink>
          </li>
        </>
      )}
    </>
  );
};

export default ItemMenu;
