import React, { useEffect, useState } from 'react';
import logo from '../../assets/img/logo.png';
import settings, { toastDocarnJuridique } from '../../config/settings';
import SpinnerApp from '../spinner/SpinnerApp';
import Select from 'react-select'
import ErrorMessage from '../body_contents/ErrorMessage';
import { getActorsResAPI, registerAdminResAPI } from '../../api/docarnJuridiqueApi';
import "../../pages/style.css";
import { NavLink } from 'react-router-dom/cjs/react-router-dom';

const RegisterModalAdmin = () => {

  const [Actors, setActors] = useState([]);
  useEffect(() => {
    getActorsResAPI()
      .then(rep => {
        setActors(rep.data.data)
      })
      .catch(err => {
        console.log(err);
      })
  }, []);

  //recupération des champ de saisis de l'utilisateur
  const [showSpinnerApp, setShowSpinnerApp] = useState(false);
  const [categoryUser, setCategoryUser] = useState(null);
  const [nameUser, setNameUser] = useState("");
  const [emailUser, setEmailUser] = useState("");
  const [phoneUser, setPhoneUser] = useState("");
  const [addressUser, setAddressUser] = useState("");
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [lien, setLien] = useState(null);

  //gestion des messages d'erreur
  const [categoryUserMsg, setCategoryUserMsg] = useState("");
  const [nameUserMsg, setNameUserMsg] = useState("");
  const [emailUserMsg, setEmailUserMsg] = useState("");
  const [phoneUserMsg, setPhoneUserMsg] = useState("");

  const ActorsOption = Actors.map((items) => {
    return { value: items.id, label: items.label };
  });

  // reinitialisation des meesage d'eereur en cas de nouvelle saisie
  useEffect(() => {
    setCategoryUserMsg("");
  }, [categoryUser]);
  useEffect(() => {
    setNameUserMsg("");
  }, [nameUser]);
  useEffect(() => {
    setEmailUserMsg("");
  }, [emailUser]);
  useEffect(() => {
    setPhoneUserMsg("");
  }, [phoneUser]);

  const getNameUser = (e) => {
    setNameUser(e.target.value);
  }
  const getEmailUser = (e) => {
    setEmailUser(e.target.value);
  }
  const getPhoneUser = (e) => {
    setPhoneUser(e.target.value);
  }
  const getAddressUser = (e) => {
    setAddressUser(e.target.value);
  }



  const handleSubmitRegister = () => (event) => {
    setShowSpinnerApp(true);
    event.preventDefault();

    // const self = this;
    if (categoryUser !== null) {
      registerAdminResAPI({ category: categoryUser.value, name: nameUser, email: emailUser, phone: phoneUser, address: addressUser })
        .then(rep => {
          if (rep.data.response.code === settings.code_success && rep.data.status) {
            toastDocarnJuridique(rep.data.response.message, settings.toastDuration + 5, "success");
            setRegisterSuccess(true);
            setMessage(rep.data.response.message)
            setLien(rep.data.data);
          } else {
            rep.data.data.category ? setCategoryUserMsg(rep.data.data.category) : setCategoryUserMsg("");
            //----------------------------
            rep.data.data.name ? setNameUserMsg(rep.data.data.name) : setNameUserMsg("");
            //----------------------------
            rep.data.data.email ? setEmailUserMsg(rep.data.data.email) : setEmailUserMsg("");
            //----------------------------
            rep.data.data.phone ? setPhoneUserMsg(rep.data.data.phone) : setPhoneUserMsg("");

            toastDocarnJuridique(rep.data.response.message, settings.toastDuration + 5, "error");
          }
          setShowSpinnerApp(false);

        })
        .catch(err => {
          console.log(err);
          toastDocarnJuridique("Erreur survenue. Veuillez controller votre connexion internet ou contactez l'administrateur si le problème persiste.", settings.toastDuration, "error");
          setShowSpinnerApp(false);

          // setCheckCniIsValide(false);

        });
    } else {
      setShowSpinnerApp(false);
      setCategoryUserMsg("Statut obligatoire");
      toastDocarnJuridique("Sélectionnez votre statut.", settings.toastDuration, "error");
    }
  }

  return (
    <div aria-hidden="true" className="onboarding-modal modal fade animated" id="RegisterModalAdmin" role="dialog">
      <div className="modal-dialog modal-centered" role="document">
        <div className="modal-content text-center">
          <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span className="close-label">Fermer <i className="os-icon os-icon-close"></i></span></button>
          <div className="onboarding-media">
            <img alt="" src={logo} width="40%" />
          </div>

          {
            !registerSuccess
              ?
              <div className="onboarding-content with-gradient">
                <h4 className="onboarding-title">
                  Enregistrement d'un nouveau client
                </h4>
                <form onSubmit={handleSubmitRegister()} >
                  <div className="onboarding-text">
                    <div className="form-group">
                      <Select placeholder="Sélection votre statut" onChange={setCategoryUser} value={categoryUser} options={ActorsOption} />
                      <ErrorMessage label={categoryUserMsg} />
                    </div>
                    <div className="form-group">
                      <input required onChange={getNameUser} className="form-control" placeholder="Nom (Obligatoire)" type="text" />
                      <ErrorMessage label={nameUserMsg} />
                    </div>
                    <div className="form-group">
                      <input required onChange={getEmailUser} className="form-control" placeholder="Adresse email (Obligatoire)" type="email" />
                      <ErrorMessage label={emailUserMsg} />
                    </div>
                    <div className="form-group">
                      <input required onChange={getPhoneUser} className="form-control" placeholder="Numéro de téléphone (Obligatoire)" type="phone" />
                      <ErrorMessage label={phoneUserMsg} />
                    </div>
                    <div className="form-group">
                      <input className="form-control" onChange={getAddressUser} placeholder="Adresse" type="text" />
                    </div>
                    {
                      !showSpinnerApp
                        ?
                        <div className="buttons-w">
                          <button className="btn btn-primary" >Inscription</button> &nbsp;&nbsp;&nbsp;
                        </div>
                        :
                        <SpinnerApp visible={showSpinnerApp} />
                    }

                  </div>
                </form>
              </div>
              :
              <>
                <br />
                <br />
                <h1 className="stylefa_success">
                  <i className="fa fa-check-circle"></i>
                </h1>
                <h6 className="stylefa_success p-3"> {message}</h6>
                <br />
                <a href={lien} target="_blank">{ lien }</a>
                
                <br />
              </>
          }
        </div>
      </div>
    </div>
  );
};

export default RegisterModalAdmin;