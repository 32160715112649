import React from "react";
import ContentLoader from "react-content-loader";
import { CONSTS } from "../../../config/constants";

import {
  HomepageLoaderContainer,
  ContainerLoader
} from "../loader.style";

const DownloadLoader = ({ backgroundColor = CONSTS._LOADER_COLOR }) => {
  return (
    <HomepageLoaderContainer>
      
      <ContainerLoader>
        <ContentLoader viewBox="0 0 380 10" backgroundColor={backgroundColor}>
          <rect x="0" y="0" rx="2" ry="2" width="60%" height="10" />
        </ContentLoader>
        <br />
        <ContentLoader viewBox="0 10 380 60" backgroundColor={backgroundColor}>
          <rect x="0" y="0" rx="2" ry="20" width="100%" height="300" />
        </ContentLoader>

        <ContentLoader viewBox="0 0 380 10" backgroundColor={backgroundColor}>
          <rect x="0" y="0" rx="2" ry="2" width="60%" height="10" />
        </ContentLoader>
        <br />
        <ContentLoader viewBox="0 10 380 60" backgroundColor={backgroundColor}>
          <rect x="0" y="0" rx="2" ry="20" width="100%" height="300" />
        </ContentLoader>

        <ContentLoader viewBox="0 0 380 10" backgroundColor={backgroundColor}>
          <rect x="0" y="0" rx="2" ry="2" width="60%" height="10" />
        </ContentLoader>
        <br />
        <ContentLoader viewBox="0 10 380 60" backgroundColor={backgroundColor}>
          <rect x="0" y="0" rx="2" ry="20" width="100%" height="300" />
        </ContentLoader>

        <ContentLoader viewBox="0 0 380 10" backgroundColor={backgroundColor}>
          <rect x="0" y="0" rx="2" ry="2" width="60%" height="10" />
        </ContentLoader>
        <br />
        <ContentLoader viewBox="0 10 380 60" backgroundColor={backgroundColor}>
          <rect x="0" y="0" rx="2" ry="20" width="100%" height="300" />
        </ContentLoader>

        <ContentLoader viewBox="0 0 380 10" backgroundColor={backgroundColor}>
          <rect x="0" y="0" rx="2" ry="2" width="60%" height="10" />
        </ContentLoader>
        <br />
        <ContentLoader viewBox="0 10 380 60" backgroundColor={backgroundColor}>
          <rect x="0" y="0" rx="2" ry="20" width="100%" height="300" />
        </ContentLoader>

        <ContentLoader viewBox="0 0 380 10" backgroundColor={backgroundColor}>
          <rect x="0" y="0" rx="2" ry="2" width="60%" height="10" />
        </ContentLoader>
        <br />
        <ContentLoader viewBox="0 10 380 60" backgroundColor={backgroundColor}>
          <rect x="0" y="0" rx="2" ry="20" width="100%" height="300" />
        </ContentLoader>

        <ContentLoader viewBox="0 0 380 10" backgroundColor={backgroundColor}>
          <rect x="0" y="0" rx="2" ry="2" width="60%" height="10" />
        </ContentLoader>
        <br />
        <ContentLoader viewBox="0 10 380 60" backgroundColor={backgroundColor}>
          <rect x="0" y="0" rx="2" ry="20" width="100%" height="300" />
        </ContentLoader>

      </ContainerLoader>  
    </HomepageLoaderContainer>
  );
};

export default DownloadLoader;
