import API from "./apiKit";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";

//API connexion
export const loginResAPI = (data) => {
    const uri = `/login`;

    return new Promise((resolve, reject) => {
        API()
        .post(uri, data)
        .then((res) => {
            resolve(res);
        }).catch((error) => reject(error));
    });
};

//API inscription temporaire
export const registerResAPI = (data) => {
    const uri = `/registration`;

    return new Promise((resolve, reject) => {
        API()
        .post(uri, data)
        .then((res) => {
            resolve(res);
        }).catch((error) => reject(error));
    });
};

//API inscription temporaire
export const registerAdminResAPI = (data) => {
    const uri = `/users`;

    return new Promise((resolve, reject) => {
        API()
        .post(uri, data)
        .then((res) => {
            resolve(res);
        }).catch((error) => reject(error));
    });
};

//API reinitialisation du mot de passe
export const initResetPasswordResAPI = (data) => {
    const uri = `/init_reset_password`;

    return new Promise((resolve, reject) => {
        API()
        .post(uri, data)
        .then((res) => {
            resolve(res);
        }).catch((error) => reject(error));
    });
};

//Information de base du formulaire de recherche.
export const infoFormConnectedResAPI = () => {
    const uri = `/infoformconnected`;

    return new Promise((resolve, reject) => {
        API()
        .get(uri)
        .then((res) => {
            resolve(res);
        }).catch((error) => reject(error));
    });
};

export const getUsersResAPI = () => {
    const uri = `/users`;

    return new Promise((resolve, reject) => {
        API()
        .get(uri)
        .then((res) => {
            resolve(res);
        }).catch((error) => reject(error));
    });
};

export const updateUsersResAPI = (data) => {
    const uri = `/users/${data.id}`;

    return new Promise((resolve, reject) => {
        API()
        .put(uri, data)
        .then((res) => {
            resolve(res);
        }).catch((error) => reject(error));
    });
};

export const configureSettingResAPI = (data) => {
    const uri = `/configure_setting`;

    return new Promise((resolve, reject) => {
        API()
        .post(uri, data)
        .then((res) => {
            resolve(res);
        }).catch((error) => reject(error));
    });
};

export const addDocumentResAPI = (data) => {
    const uri = `/documents`;

    return new Promise((resolve, reject) => {
        API()
        .post(uri, data)
        .then((res) => {
            resolve(res);
        }).catch((error) => reject(error));
    });
};

//Information de base du formulaire de recherche.
export const infoFormResAPI = () => {
    const uri = `/infoForm`;

    return new Promise((resolve, reject) => {
        API()
        .get(uri)
        .then((res) => {
            resolve(res);
        }).catch((error) => reject(error));
    });
};

//Information de base du formulaire de recherche.
export const getCategorieResAPI = () => {
    const uri = `/infoForm`;

    return new Promise((resolve, reject) => {
        API()
        .get(uri)
        .then((res) => {
            resolve(res);
        }).catch((error) => reject(error));
    });
};

//les actes rescemments enreigstrés
export const rescentsResAPI = () => {
    const uri = `/rescents`;

    return new Promise((resolve, reject) => {
        API()
        .get(uri)
        .then((res) => {
            resolve(res);
        }).catch((error) => reject(error));
    });
};

//API moteur de recherche
export const searchResAPI = (data) => {
    const uri = `/searchfiles`;
    return new Promise((resolve, reject) => {
        API()
        .post(uri, data)
        .then((res) => {
            resolve(res);
        }).catch((error) => {
            reject(error)
        });
    });
};

//API moteur de recherche
export const usersSearchResAPI = (data) => {
    const uri = `/usersSearch`;
    return new Promise((resolve, reject) => {
        API()
        .post(uri, data)
        .then((res) => {
            resolve(res);
        }).catch((error) => {
            reject(error)
        });
    });
};

//API vérification téléchargement d'un fichier
export const checkDownloadResAPI = (data) => {
    const uri = `/downloads`;

    return new Promise((resolve, reject) => {
        API()
        .post(uri, data)
        .then((res) => {
            resolve(res);
        }).catch((error) => {
            reject(error)});
    });
};


export const downloadResAPI = (link) => {
    const uri = `/downloadfile?link=` + link;

    let promise = new Promise((resolve) =>
    API().get(uri, {responseType: 'blob' })
    .then(response => {
      const blob = new Blob([response.data], {
        type: 'blob',
      });
      fileDownload(blob, link); // npm " js-file-download" responsible for downloading the file for the user
      resolve(response.data)
  }));

  toast.promise(promise,{
    pending: "Téléchargement en cours ...",
    loading: 'Téléchargement en cours ...',
    success: 'Téléchargement effectué avec succès',
    error: 'Erreur de téléchargement'
  },
  {
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

//API téléchargement d'un fichier
export const downloadListResAPI = () => {
    const uri = `/downloads`;

    return new Promise((resolve, reject) => {
        API()
        .get(uri, {})
        .then((res) => {
            resolve(res.data);
        }).catch((error) => reject(error));
    });
};

//API téléchargement d'un fichier
export const favoryListResAPI = () => {
    const uri = `/favorys`;

    return new Promise((resolve, reject) => {
        API()
        .get(uri, {})
        .then((res) => {
            resolve(res.data);
        }).catch((error) => reject(error));
    });
};

//Ajouter
export const favoryAddResAPI = (data) => {
    const uri = `/favorys`;

    return new Promise((resolve, reject) => {
        API()
        .post(uri, data)
        .then((res) => {
            resolve(res);
        }).catch((error) => reject(error));
    });
};


//activer un compte
export const activeAccountResAPI = (data) => {
    const uri = `/activate`;

    return new Promise((resolve, reject) => {
        API()
        .post(uri, data)
        .then((res) => {
            resolve(res);
        }).catch((error) => reject(error));
    });
};

export const checkResetPasswordTokenResAPI = (data) => {
    const uri = `/check_reset_password_token`;

    return new Promise((resolve, reject) => {
        API()
        .post(uri, data)
        .then((res) => {
            resolve(res);
        }).catch((error) => reject(error));
    });
};


export const resetPasswordTokenResAPI = (data) => {
    const uri = `/reset_password_token`;

    return new Promise((resolve, reject) => {
        API()
        .post(uri, data)
        .then((res) => {
            resolve(res);
        }).catch((error) => reject(error));
    });
};


//Liste des rôles public du sysème.
export const getRolesResAPI = () => {
    const uri = `/roles`;

    return new Promise((resolve, reject) => {
        API()
        .get(uri)
        .then((res) => {
            resolve(res.data);
        }).catch((error) => reject(error));
    });
};

//Liste des acteurs public du sysème.
export const getActorsResAPI = () => {
    const uri = `/getactors`;

    return new Promise((resolve, reject) => {
        API()
        .get(uri)
        .then((res) => {
            resolve(res);
        }).catch((error) => reject(error));
    });
};