import { configureStore,combineReducers } from '@reduxjs/toolkit';
import sessionStorage from 'redux-persist/es/storage/session';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import UserSlice from "./user/UserSlice";
import ConfigurationSlice from './configuration/ConfigurationSlice';
import PageSlice from './page/PageSlice';
import SearchSlice from './search/SearchSlice';

const persistConfig = {
  key: 'root',
  storage:sessionStorage, //use storageSession if you wan to store the value in the session
  stateReconciler: autoMergeLevel2,//used to mange the merge state
  blacklist: [], //list some element to unpersiste
  whitelist: ['user',"configuration"],//list some element to persist
}

const rootReducer = combineReducers({ 
  user: UserSlice,
  page: PageSlice,
  configuration: ConfigurationSlice,
  search: SearchSlice,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
})

export const persistor = persistStore(store)