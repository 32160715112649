import React, { useEffect, useState } from "react";
import RescentLoader from "../Loaders/RescentLoader/RescentLoader.loader";
import { rescentsResAPI } from "../../api/docarnJuridiqueApi";
import { useSelector } from "react-redux";
import { getAuthentificated } from "../../store/user/UserSlice";
import { getIsLoader } from "../../store/page/PageSlice";
import LoaderPage from "./contents/LoaderPage";


const RescentDocuments = ({ handleSubmitDownloadFile }) => {

  const [modeLoading, setModeLoading] = useState(true); 
  const [result, setResult] = useState([]);
  const authenticated = useSelector(getAuthentificated);
  const isLoader = useSelector(getIsLoader);

  useEffect(() => {
  rescentsResAPI()
      .then(rep => { 
        setResult(rep.data.data);
        setModeLoading(false);
      })
      .catch(err => {
        console.log(err);
      })
  }, []);
  
  const rescentList = result.map((items) => {
    return (
      <>
      {
        authenticated
        ?
        <button onClick={() => handleSubmitDownloadFile(items, null)} className="form-control btn">
        <b>
          {items.category}&nbsp;N°{items.number} &nbsp;du &nbsp;{items.datepublication}
        </b>
      </button>
        :
        <button disabled className="form-control">
          <b>
            {items.category}&nbsp;N°{items.number} &nbsp;du &nbsp;{items.datepublication}
          </b>
        </button>
      }
      </>
      
    );
  });
  return (
    <div className="content-panel">
      {
      isLoader && <LoaderPage message={"Action en cours..."} />
    }
      <div className="content-panel-close">
        <i className="os-icon os-icon-close"></i>
      </div>
      <div className="element-wrapper">
        <h6 className="element-header">Les plus récents</h6>
        <div className="element-box-tp">
          {
            modeLoading === true
            ?
            <RescentLoader/>
            :
            <div className="el-buttons-list full-width">{rescentList}</div>
          }
          
        </div>
      </div>
    </div>
  );
};

export default RescentDocuments;
