import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import avatar from "../../assets/img/avatar.png";
import { getAuthentificated, logout } from "../../store/user/UserSlice";

const AvatarUser = () => {
  const authentificated = useSelector(getAuthentificated);
  const dispatch = useDispatch();
  // console.log("Utilisateur >>", user);
  return (
    <>
    {
      authentificated
      ?
    <>
    <div className="logged-user-w">
      <div className="logged-user-i">
        <div className="avatar-w">
          <img alt="" src={avatar} />
        </div>
        <div className="logged-user-menu color-style-bright">
          <div className="logged-user-avatar-info">
            <div className="avatar-w">
              <img alt="" src={avatar} />
            </div>
            <div className="logged-user-info-w">
              {/* <div className="logged-user-name"> { user.name }</div>
              <div className="logged-user-role">{ user.accounts.login }</div> */}
            </div>
          </div>
          <div className="bg-icon">
            <i className="os-icon os-icon-wallet-loaded"></i>
          </div>
          <ul>
            <li>
              <Link to="/#">
                <i className="os-icon os-icon-user-male-circle2"></i>
                <span>Profil</span>
              </Link>
            </li>
            <li>
              <Link to="/#"  onClick={ () => dispatch(logout()) }>
                <i className="fa fa-sign-out"></i>
                <span>Déconnexion</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    </>
    :
    <>
    <div className="logged-user-w">
      <div className="logged-user-i">
        <div className="avatar-w">
          <img alt="" src={avatar} />
        </div>
        <div className="logged-user-menu color-style-bright">
          <div className="bg-icon">
            <i className="os-icon os-icon-wallet-loaded"></i>
          </div>
          <ul>
            <li>
              <Link to="/#"  data-target="#LoginModal" data-toggle="modal">
                <i className="fa fa-sign-in"></i>
                <span>Connexion</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    </>
    }
    </>
  );
  
};

export default AvatarUser;
