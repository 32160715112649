import React from "react";
import "./Error.css"

const ErrorMessage = (message) => {
  return (
    <div>
      <strong className="styleError">{message.label}</strong>
    </div>
  );
};

export default ErrorMessage;
