import React, { useEffect, useState } from "react";
import Select from "react-select";
import settings, { modifiedObjectArray, toastDocarnJuridique } from "../../config/settings";
import SpinnerApp from "../spinner/SpinnerApp";
import SearchLoader from "../Loaders/SearchLoader/SearchLoader.loader";
import logApp from "../../utils/logApp";
import { getActorsResAPI, getUsersResAPI, searchResAPI, updateUsersResAPI, usersSearchResAPI } from "../../api/docarnJuridiqueApi";
import { getModeList } from "../../store/configuration/ConfigurationSlice";
import { useSelector } from "react-redux";
import TrLineUsers from "./contents/TrLineUsers";
import { getAuthentificated, selectCurrentUser } from "../../store/user/UserSlice";
import PaginationComponent from "../pagination/PaginationComponent";
import { useDispatch } from "react-redux";
import { getData, setCurrentPage, setData, setFavoryData, setLinks, setNextPageUrl, setPrevPageUrl, setSateData, setSizePage } from "../../store/page/PageSlice";
import API from "../../api/apiKit";
// import { DisplayModeDark } from "../../store/reducers/configuration/configuration.actions";

const ListUsers = ({handleAddFavory, handleSubmitDownloadFile}) => {
  const [showSpinnerApp, setShowSpinnerApp] = useState(false);
  const [Years, setYears] = useState([]);
  const [userId, setUserId] = useState(1);
  const [Categorys, setCategorys] = useState([]);
  const [yearChoice, setYearChoice] = useState(null);
  const [categoryChoice, setCategoryChoice] = useState(null);
  const [search, setSearch] = useState("");
  const [modeLoading, setModeLoading] = useState(true);
  const [baseUri, setBaseUri] = useState("listDocument");
  const authenticated = useSelector(getAuthentificated);
  const data = useSelector(getData);
  const currentUser = useSelector(selectCurrentUser);
  
  const modeList = useSelector(getModeList);

  const dispatch = useDispatch();

  useEffect(() => { 
    if (authenticated) {

      setUserId(currentUser.id)
      setBaseUri("listDocumentConnected");
      getUsersResAPI()
        .then(rep => {
          dispatch(setData(rep.data.data.data));
          dispatch(setLinks(rep.data.data.links));
          dispatch(setPrevPageUrl(rep.data.data.prev_page_url));
          dispatch(setNextPageUrl(rep.data.data.next_page_url));
          dispatch(setCurrentPage(rep.data.data.current_page));
          dispatch(setSizePage(rep.data.data.per_page));

          setModeLoading(false);
        })
        .catch(err => {
          console.log(err);
        })
    }

  }, []);

  useEffect(() => {
    getActorsResAPI()
        .then(rep => {
          setCategorys(rep.data.data);
          setModeLoading(false);
        })
        .catch(err => {
          console.log(err);
        })
    
  },[])

  const yearsOption = Years.map((items) => {
    return { value: items.publication, label: items.publication };
  });
  //On ajoute l'élément en début du ta
  yearsOption.unshift({ value: "", label: "Toutes" });
  const categorysOption = Categorys.map((items) => {
    return { value: items.id, label: items.label };
  });
  categorysOption.unshift({ value: "", label: "Toutes" });

  const getSearch = (e) => {
    setSearch(e.target.value);
  };
  // const dispatch = useDispatch();
  const handleSubmitSearch = () => (event) => {
    // dispatch(DisplayModeDark(false));
    setShowSpinnerApp(true);
    setModeLoading(true);
    event.preventDefault();
    
    const yearVal = yearChoice !== null ? yearChoice.value : "";
    const categoryVal = categoryChoice !== null ? categoryChoice.value : "";

    // console.log(yearVal, categoryVal, search);
    usersSearchResAPI({category: categoryVal, search: search})
      .then((rep) => {
        dispatch(setData(rep.data.data.data));
        dispatch(setLinks(rep.data.data.links));
        dispatch(setPrevPageUrl(rep.data.data.prevPageUrl));
        dispatch(setNextPageUrl(rep.data.data.nextPageUrl));
        dispatch(setCurrentPage(rep.data.data.currentPage));
        dispatch(setSizePage(rep.data.data.per_page));

        setShowSpinnerApp(false);
        setModeLoading(false);
      })
      .catch((error) => {
        toastDocarnJuridique(error.response.data.message, settings.toastDuration, "error");

        setShowSpinnerApp(false);
      });
  };
  // if (data.length === 0) data = initialData;
  

  const handleChangePage = (uri = "") => {
  
    const yearVal = yearChoice !== null ? yearChoice.value : "";
    const categoryVal = categoryChoice !== null ? categoryChoice.value : "";

    if (uri) {
      // setModeLoading(true);
      return new Promise((resolve, reject) => {
            API()
            .post("usersSearch"  + uri.replace(/\//g, ""), {category: categoryVal, search: search })
            .then((rep) => {
              // console.log("rep.data.data.data", rep.data.data);
              dispatch(setData(rep.data.data.data));
              dispatch(setLinks(rep.data.data.links));
              dispatch(setPrevPageUrl(rep.data.data.prev_page_url));
              dispatch(setNextPageUrl(rep.data.data.next_page_url));
              dispatch(setCurrentPage(rep.data.data.current_page));
              dispatch(setSizePage(rep.data.data.per_page));
              
              // setModeLoading(false);

            }).catch((error) => reject(error));
        });
    }
  }

  const handleLockUnlock = (val, stateVal) => {
    const dataEdit = {
      id:val.id,
      role_id:val.role_id,
      actor_id:val.actor_id,
      email:val.email,
      name:val.name,
      phone:val.phone,
      state:stateVal,
      actor:val.actor,
      accounts:val.accounts,
    };
  
      // console.log(yearVal, categoryVal, search);
      updateUsersResAPI(dataEdit)
        .then((rep) => {

          const d = modifiedObjectArray(data, 'id', val.id, dataEdit);
          dispatch(setData(d));
        
          // dispatch(setData(rep.data.data.data));
          // dispatch(setLinks(rep.data.data.links));
          // dispatch(setPrevPageUrl(rep.data.data.prevPageUrl));
          // dispatch(setNextPageUrl(rep.data.data.nextPageUrl));
          // dispatch(setCurrentPage(rep.data.data.currentPage));
          // dispatch(setSizePage(rep.data.data.per_page));
  
          // setShowSpinnerApp(false);
          // setModeLoading(false);
          toastDocarnJuridique("Mise à jour effectuée avec succès", settings.toastDuration, "success");
        })
        .catch((error) => {
          toastDocarnJuridique(error.response.data.message, settings.toastDuration, "error");
  
          // setShowSpinnerApp(false);
        });
    };
  const itemsTable = data.map((items, index) => { console.log("items", items);
    return <TrLineUsers handleLockUnlock={handleLockUnlock} handleSubmitDownloadFile={handleSubmitDownloadFile} handleAddFavory={handleAddFavory} index={index} key={index} data={items} />;
  });

  
  const previewPage = () => {
    
  }

  const nextPage = () => {
      
  }

  const paginateConfig = {
    data: [],
    numbers: 15,
    currentPage: 1,
    urlPagination: '',
    previewPage: previewPage,
    nextPage: nextPage,
  }
  return (
    <div>
      <div className="row">
        <div className="col-sm-12">
          <div className="element-wrapper">
            
              <div className="row">
                <div className="col-xs-6 col-sm-4 col-xxxl-6">
                  <label></label>
                  <div className=" ">
                    <Select
                      placeholder="Catégorie"
                      onChange={setCategoryChoice}
                      value={categoryChoice}
                      options={categorysOption}
                    />
                  </div>
                </div>
                <div className="col-sm-8 col-xxxl-12">
                <form onSubmit={handleSubmitSearch()}>
                  <label></label>
                  <div className="input-group">
                    <input
                      onChange={getSearch}
                      className="text-docarn form-control rounded"
                      placeholder="Rechercher un utilisateur ici..."
                      type="text"
                    />
                    <div className="input-group-btn">
                      {showSpinnerApp === true ? (
                        <SpinnerApp visible={showSpinnerApp} />
                      ) : (
                        <a href="/#" onClick={handleSubmitSearch()}>
                          <i className="newLocal_4 icon-w fa fa-search"></i>
                        </a>
                      )}
                    </div>
                  </div>
                  </form>
                </div>
              </div>
            
            <div className="row newLocal_0">
              <div className="col-10">
                {modeLoading === true ? (
                  <SearchLoader />
                ) : 
                <div className="user-name newLocal_1">
                      <div className="table-responsive">
                        <table id="dataTable" className="table table-striped table-lightfont">
                          <thead>
                            <tr>
                              <th>Nom</th>
                              <th>Catégorie</th>
                              <th>Compte(s)</th>
                              <th>Email</th>
                              <th>Phone</th>
                              <th>Statut</th>
                              <th>Adresse</th>
                              <th>Date enreg</th>
                              <th>#</th>
                            </tr>
                          </thead>
                          <tbody>
                            {itemsTable}
                          </tbody>
                        </table>
                      </div>
                    </div>
                 }
                <PaginationComponent setModeLoading = {setModeLoading} handleChangePage = {handleChangePage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListUsers;
