import React from "react";
import "./Item.style.css";
import logApp from "../../../utils/logApp";
import { useDispatch, useSelector } from "react-redux";
import { getAuthentificated, setDataUser } from "../../../store/user/UserSlice";
import SettingsModal from "./SettingsModal";

const TrLineUsers = (prop) => {
  const authenticated = useSelector(getAuthentificated);
  const Item = prop.data;
  const Index = prop.index;
  const dispatch = useDispatch();
  logApp('ReLine.jsx', 'ligne du tableau', Item);
  

  const comptes = Item.accounts.map((items, index) => { console.log("items", items);
    return <>
     { items.login }, &nbsp;&nbsp;
    </>;
  });

  
  
  return (
    <>
    <SettingsModal />
      <tr>
        <td> <h6 className="Item_style"> { Item.name } </h6> </td>
        <td> {Item && Item.actor && Item.actor.label} {Item && Item.accounts && Item.accounts.length} Compte(s) </td>
        <td className="styleItem"> {comptes} </td>
        <td> {Item.email} </td>
        <td> {Item.phone} </td>
        <td>
        {
          Item.state
          ?
          <span className="statutactifbuton" >Actif</span>
          :
          <span className="statutinactifbuton">Inactif</span>
        }
        </td>
        <td> {Item.address} </td>
        <td> {Item.created_at} </td>
        <td>
            <a onClick={() => prop.handleLockUnlock(Item, !Item.state)}>
                <i className={Item.state ? "fa fa-lock" : " fa fa-unlock"}> </i>
            </a>
            <a onClick={() => prop.handleSubmitDownloadFile(Item, Index)}>
                <i className="fa fa-trash"> </i>
            </a>
            <a onClick={() => prop.handleSubmitDownloadFile(Item, Index)}>
                <i className="fa fa-edit"> </i>
            </a>
            <a className=" " onClick={() => prop.handleSubmitDownloadFile(Item, Index)}>
                <i className="fa fa-envelope"> </i>
            </a>
            <a  data-target="#SettingsModal" data-toggle="modal" onClick={() => dispatch(setDataUser(Item)) }>
                <i className="fa fa-gear"> </i>
            </a>
           
        </td>
      </tr>
    </>
  );
};

export default TrLineUsers;
