import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { useLocation } from "react-router-dom";
import { activeAccountResAPI } from "../api/docarnJuridiqueApi";
import settings, { toastDocarnJuridique } from "../config/settings";
import "./style.css";
import SpinnerApp from "../components/spinner/SpinnerApp";
import { NavLink } from 'react-router-dom/cjs/react-router-dom';
import logo from "../assets/img/logo.png";

const AccountActive = (data) => {
  let location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [code, setCode] = useState(null);
  const [token, setToken] = useState(null);
  const [loader, setLoader] = useState(true);
  const [validationOK, setValidationOK] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    setCode(searchParams.get("c"));
    setToken(searchParams.get("t"));
    setLoader(true);
    if (code && token) {
      
      activeAccountResAPI({ code: code, token: token })
        .then((rep) => {
          if (
            rep.data.status &&
            rep.data.response.code === settings.code_success
          ) {
            toastDocarnJuridique(
              rep.data.response.message,
              settings.toastDuration,
              "success"
            );
            setValidationOK(true);
          } else {
            toastDocarnJuridique(
              rep.data.response.message,
              settings.toastDuration + 19,
              "error"
            );

            setValidationOK(false);
          }
          setLoader(false);
          setMessage(rep.data.response.message);
        })
        .catch((error) => {
          setLoader(false);
          console.log(error.message);
        });
    }
  }, [code]);

  return (
    <div>
      <div className="content-i">
        <div className="content-box">
          <div className="os-tabs-w">
            <div className="os-tabs-controls os-tabs-complex">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <h2>
                    <i className="fa fa-check-circle"></i> Validation de votre
                    compte
                  </h2>
                </li>
              </ul>
            </div>
          </div>
          <div className="big-error-w">
          <center>
            <img alt="" src={ logo } width="50%" />
            </center>
            {loader ? (
              <>
                <center><SpinnerApp visible={true} /></center>
                <h5>Veuillez patienter un instant...</h5>
              </>
            ) : (
              <>
                <h1
                  className={validationOK ? "stylefa_success" : "stylefa_error"}
                >
                  <i
                    className={
                      validationOK ? "fa fa-check-circle" : "fa fa-times-circle"
                    }
                  ></i>
                </h1>
                <h6 className={
                      validationOK ?  "stylefa_success" : "stylefa_error"
                    } > {message}</h6>
                <form>
                  <div className="input-group">
                    <div className="input-group-btn">
                    <NavLink to="/"  className="btn btn-primary">
                  <div className="icon-w">
                  </div>
                  <span >Retourner à l'accueil</span>
                </NavLink>
                    </div>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountActive;
