import React from "react";
import "./Item.style.css";
import SpinnerApp from "../../spinner/SpinnerApp";
import { useSelector } from "react-redux";
import { getAuthentificated } from "../../../store/user/UserSlice";

const ItemDocument = (prop) => {
  const authenticated = useSelector(getAuthentificated);
  const Documents = prop.document;
  const Index = prop.index;

  return (
    <div className="user-name newLocal_1">
      <h6 className="user-title onboarding-title">
        {/* <strong> JO-CAMEROUN N°22 du 2010-12-01  &nbsp; <i className="fa fa-star"></i></strong> */}
        {authenticated === true ? (
          <>
            {/* {Documents.state && <SpinnerApp visible={true} />} */}
            <strong
              id={"Item" + prop.key}
              className="pointer Item_style"
              onClick={() => prop.handleSubmitDownloadFile(Documents, Index)}
            >
              {Documents.category}&nbsp;N°{Documents.number} &nbsp;du &nbsp;
              {Documents.datepublication}
            </strong>

            &nbsp;&nbsp;
            {Documents.favory ? (
              <i
                className="pointer OnFavory fa fa-star"
                onClick={() => prop.handleAddFavory(Documents, false, Index)}
              ></i>
            ) : (
              <i
                className="pointer OffFavory fa fa-star"
                onClick={() => prop.handleAddFavory(Documents, true, Index)}
              ></i>
            )}
          </>
        ) : (
          <strong  className="pointer Item_style">
            <div href="/#" data-target="#LoginModal" data-toggle="modal">
            {Documents.category}&nbsp;N°{Documents.number} &nbsp;du &nbsp;
            {Documents.datepublication} 
              </div>
          </strong>
        )}
      </h6>
      <div className="">{Documents.description}</div>
    </div>
  );
};

export default ItemDocument;
