import React from "react";
import { useSelector } from "react-redux";
import Body from "../components/Body";
import Header from "../components/Header";
import { CONSTS } from "../config/constants";
import { getAuthentificated } from "../store/user/UserSlice";

const Favory = () => {
  const authenticated = useSelector(getAuthentificated);

  return (
    <div>
      <Header page={CONSTS._FAVORY} />
      {authenticated === true ? (
        <>
          <Body activePage={CONSTS._FAVORY} />
        </>
      ) : (
        <>
          <Body activePage={CONSTS._HOME} />
        </>
      )}
    </div>
  );
};

export default Favory;
