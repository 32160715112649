import axios from 'axios';
import { useDispatch } from 'react-redux';
import settings, { toastDocarnJuridique } from '../config/settings';
import { logout } from '../store/user/UserSlice';
import { getToken } from '../utils/localstorage';

const API_URL = settings.baseUrlAPI;
const local = getToken(settings.localStorageName);
const token = (local) ? local : null;



const API = (baseUrl = API_URL) => {
  // const dispatch = useDispatch();
    const api = axios.create({
        baseURL: API_URL,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    api.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // window.location.reload();
    return response;
  }, function (error) {
    // console.log("error", error);
    if(error.response.status === 401){
        // window.location.reload(true);
        toastDocarnJuridique("Session expirée. Veuillez vous reconnecter", settings.toastDuration, "error");

        // state.user = null;
        // state.token = null;
        // state.authentificated = false;

        // dispatch(logout());
    }
    return Promise.reject(error);
  });

  return api;
} 


// API.interceptors.response.use(function (response) {
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     // Do something with response data
//     // window.location.reload();
//     console.log(response);
//     return response;
//   }, function (error) {
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with response error
//     toastDocarnJuridique(error, settings.toastDuration, "error");
//     console.log("error", error);
//     if(error.response.status === 401){
//         // window.location.reload(true);
//         toastDocarnJuridique("Session expirée. Veuillez vous reconnecter", settings.toastDuration, "error");
//     }
//     return Promise.reject(error);
//   });




export default API;