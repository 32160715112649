import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { useLocation } from "react-router-dom";
import {
  activeAccountResAPI,
  checkResetPasswordTokenResAPI,
  resetPasswordTokenResAPI,
} from "../api/docarnJuridiqueApi";
import settings, { toastDocarnJuridique } from "../config/settings";
import "./style.css";
import SpinnerApp from "../components/spinner/SpinnerApp";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import logo from "../assets/img/logo.png";
import ErrorMessage from "../components/body_contents/ErrorMessage";

const ResetPassword = (data) => {
  let location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [password, setPassword] = useState(null);
  const [passwordMsg, setPasswordMsg] = useState(null);
  const [confirm, setConfirm] = useState(null);
  const [confirmMsg, setConfirmMsg] = useState(null);
  const [code, setCode] = useState(null);
  const [token, setToken] = useState(null);
  const [showSpinnerApp, setShowSpinnerApp] = useState(false);
  const [loader, setLoader] = useState(true);
  const [validationOK, setValidationOK] = useState(false);
  const [message, setMessage] = useState("");

  const [resetSuccess, setResetSuccess] = useState(false);
  const [messageReset, setMessageRest] = useState("");

  useEffect(() => {
    setCode(searchParams.get("c"));
    setToken(searchParams.get("t"));
    setLoader(true);
    if (code && token) {
      checkResetPasswordTokenResAPI({ code: code, token: token })
        .then((rep) => {
          if (
            rep.data.status &&
            rep.data.response.code === settings.code_success
          ) {
            setValidationOK(true);
          } else {
            toastDocarnJuridique(
              rep.data.response.message,
              settings.toastDuration + 19,
              "error"
            );

            setValidationOK(false);
          }
          setLoader(false);
          setMessage(rep.data.response.message);
        })
        .catch((error) => {
          setLoader(false);
          console.log(error.message);
        });
    }
  }, [code]);

  const handleSubmit = () => (event) => {
    setShowSpinnerApp(true);
    event.preventDefault();

    // const self = this;
    if (password !== null && confirm !== null) {
      resetPasswordTokenResAPI({
        code: code,
        token: token,
        password: password,
        confirm: confirm,
      })
        .then((rep) => {
          if (
            rep.data.response.code === settings.code_success &&
            rep.data.status
          ) {
            toastDocarnJuridique(
              rep.data.response.message,
              settings.toastDuration + 5,
              "success"
            );
            setResetSuccess(true);
            setMessageRest(rep.data.response.message);
          } else {
            rep.data.data.category
              ? setPasswordMsg(rep.data.data.category)
              : setPasswordMsg("");
            //----------------------------
            rep.data.data.name
              ? setConfirmMsg(rep.data.data.phone)
              : setConfirmMsg("");

            toastDocarnJuridique(
              rep.data.response.message,
              settings.toastDuration + 5,
              "error"
            );
          }
          setShowSpinnerApp(false);
        })
        .catch((err) => {
          console.log(err);
          toastDocarnJuridique(
            "Erreur survenue. Veuillez controller votre connexion internet ou contactez l'administrateur si le problème persiste.",
            settings.toastDuration,
            "error"
          );
          setShowSpinnerApp(false);

          // setCheckCniIsValide(false);
        });
    } else {
      setShowSpinnerApp(false);
      setPasswordMsg("champ obligatoire");
      setConfirmMsg("champ obligatoire");
      toastDocarnJuridique(
        "Sélectionnez votre statut.",
        settings.toastDuration,
        "error"
      );
    }
  };

  return (
    <div>
      <div className="content-i">
        <div className="content-box">
          <div className="os-tabs-w">
            <div className="os-tabs-controls os-tabs-complex">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <h2>
                    <i className="fa fa-refresh"></i> Réinitialisation du mot de
                    passe
                  </h2>
                </li>
              </ul>
            </div>
          </div>
          <div className="big-error-w">
            <center>
              <img alt="" src={logo} width="50%" />
            </center>
            {loader ? (
              <>
                <center>
                  <SpinnerApp visible={true} />
                </center>
                <h5>Veuillez patienter un instant...</h5>
              </>
            ) : (
              <>
                {
                !validationOK ? (
                  <>
                    <h1 className="stylefa_error">
                      <i className="fa fa-times-circle"></i>
                    </h1>
                    <h6 className="stylefa_error"> {message}</h6>
                    <form>
                      <div className="input-group">
                        <div className="input-group-btn">
                          <NavLink to="/" className="btn btn-primary">
                            <div className="icon-w"></div>
                            <span>Retourner à l'accueil</span>
                          </NavLink>
                        </div>
                      </div>
                    </form>
                  </>
                ) : (
                  <>
                    {!resetSuccess ? (
                      <div className="onboarding-content with-gradient">
                        <h4 className="onboarding-title">
                          Modifier votre mot de passe
                        </h4>
                        <form onSubmit={handleSubmit()}>
                          <div className="onboarding-text">
                            <div className="form-group">
                              <input
                                className="form-control"
                                required
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Mot de passe"
                                type="password"
                              />
                            </div>
                            <div className="form-group">
                              <input
                                className="form-control"
                                required
                                onChange={(e) => setConfirm(e.target.value)}
                                placeholder="Confirimation du mot de passe"
                                type="password"
                              />
                            </div>
                            {!showSpinnerApp ? (
                              <div className="buttons-w">
                                <button className="btn btn-primary">
                                  Modifier
                                </button>{" "}
                                &nbsp;&nbsp;&nbsp;
                              </div>
                            ) : (
                              <SpinnerApp visible={showSpinnerApp} />
                            )}
                          </div>
                        </form>
                      </div>
                    ) : (
                      <>
                        <br />
                        <br />
                        <h1 className="stylefa_success">
                          <i className="fa fa-check-circle"></i>
                        </h1>
                        <h6 className="stylefa_success"> {messageReset}</h6>
                        <br />
                        <div className="input-group">
                        <div className="input-group-btn">
                          <NavLink to="/" className="btn btn-primary">
                            <div className="icon-w"></div>
                            <span>Retourner à l'accueil</span>
                          </NavLink>
                        </div>
                      </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
