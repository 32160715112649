import React from "react";
import ContentLoader from "react-content-loader";
import { CONSTS } from "../../../config/constants";

import {
  HomepageLoaderContainer,
  ContainerLoader
} from "../loader.style";

const RescentLoader = ({ backgroundColor = CONSTS._LOADER_COLOR }) => {
  return (
    <HomepageLoaderContainer>
      
      <ContainerLoader>
        <ContentLoader viewBox="0 0 380 50" backgroundColor={backgroundColor}>
          <rect x="0" y="0" rx="0" ry="0" width="100%" height="100" />
        </ContentLoader>
        <br />
        <br />
        <ContentLoader viewBox="0 0 380 50" backgroundColor={backgroundColor}>
          <rect x="0" y="0" rx="0" ry="0" width="100%" height="100" />
        </ContentLoader>
        <br />
        <br />
        <ContentLoader viewBox="0 0 380 50" backgroundColor={backgroundColor}>
          <rect x="0" y="0" rx="0" ry="0" width="100%" height="100" />
        </ContentLoader>
        <br />
        <br />
        <ContentLoader viewBox="0 0 380 50" backgroundColor={backgroundColor}>
          <rect x="0" y="0" rx="0" ry="0" width="100%" height="100" />
        </ContentLoader>
        <br />
        <br />
        <ContentLoader viewBox="0 0 380 50" backgroundColor={backgroundColor}>
          <rect x="0" y="0" rx="0" ry="0" width="100%" height="100" />
        </ContentLoader>
        <br />
        <br />
        <ContentLoader viewBox="0 0 380 50" backgroundColor={backgroundColor}>
          <rect x="0" y="0" rx="0" ry="0" width="100%" height="100" />
        </ContentLoader>
        <br />
        <br />
        <ContentLoader viewBox="0 0 380 50" backgroundColor={backgroundColor}>
          <rect x="0" y="0" rx="0" ry="0" width="100%" height="100" />
        </ContentLoader>
        <br />
        <br />
        <ContentLoader viewBox="0 0 380 50" backgroundColor={backgroundColor}>
          <rect x="0" y="0" rx="0" ry="0" width="100%" height="100" />
        </ContentLoader>
        <br />
        <br />
        
        
      </ContainerLoader>  
    </HomepageLoaderContainer>
  );
};

export default RescentLoader;
