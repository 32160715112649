import React from "react"
import logo from "../assets/img/logo.png";
import hero from "../assets/img/hero.png";
import doc2 from "../assets/img/doc2.jpg";
import values1 from "../assets/img/values1.png";
import values2 from "../assets/img/values2.png";
import values3 from "../assets/img/values3.png";
import pricing1 from "../assets/img/pricing1.png";
import pricing2 from "../assets/img/pricing2.png";
import pricing3 from "../assets/img/pricing3.png";
import pricing4 from "../assets/img/pricing4.png";
import mtn from "../assets/img/mtn.jpg";
import sabc from "../assets/img/sabc.png";
import guinness from "../assets/img/guinness.png";
import cotco from "../assets/img/cotco.png";
import ucb from "../assets/img/ucb.png";
import edf from "../assets/img/edf.png";
import afriland from "../assets/img/afriland.png";
import globeleq from "../assets/img/GLobeleq_Logo-1.jpg";
import pwc from "../assets/img/pwc.png";
import hevecam from "../assets/img/hevecam.png";
import juritax from "../assets/img/juritax.png";
import mazars from "../assets/img/mazars.png";
import core from "../assets/img/core.png";
import brainconsulting from "../assets/img/brain consulting.png";
import notaire from "../assets/img/notaire.PNG";
import ngue from "../assets/img/ngue.png";

import bg from "../assets/img/bg.jpg";
import { CONSTS } from "../config/constants";
import './style2.css';
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import settings from "../config/settings";
const About = () => {
  
  return (
    <div className="bg-content">
     
          {/* ======= Header ======= */}
          <header id="header" className="header fixed-top">
            <div className="container-fluid container-xl d-flex align-items-center justify-content-between">

              <a href="index.html" className="logo d-flex align-items-center">
                  <img alt="" src={ logo } width={100}  />
              </a>

              <nav id="navbar" className="navbar">
                <ul>
                  <li> <NavLink to="/"  className="getstarted btn-primary scrollto">Commencer la recherche</NavLink></li>
                </ul>
                <i className="bi bi-list mobile-nav-toggle"></i>
              </nav>{/* .navbar */}

            </div>
          </header>{/* End Header */}

          {/* ======= Hero Section ======= */}
        <section id="hero" className="hero d-flex align-items-center">

        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex flex-column justify-content-center">
              <h1 className="Text-title-1" >DOCARN Juridique, la première plateforme  de recherche documentaire. </h1>
              <p className="text-justify">
              Vous souhaitez retrouver un acte juridique, des extraits de journaux officiels, des textes de loi Camerounais, des Décrets PR et PM, des Arrêtés, des Circulaires, des Décisions… datant des années antérieures au Cameroun. Plus encore, vous désirez travailler avec des extraits de Bulletins ou de textes complets OHADA, CEMAC, COBAC… <br /><br />

              <span className="fond-weight-bold">Ne perdez plus de temps, DOCARN est la solution à vos problèmes !</span>

              </p>
              <div className="p-3">
                <div className=" text-lg-start">
                <NavLink to="/" className="btn btn-lg getstarted btn-primary scrollto ">
                    <i className="fa fa-search"></i>
                    <span> Commencer la recherche</span>
                    
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="col-lg-6 hero-img"   >
              <img alt="" src={ hero } className="img-fluid" />
            </div>
          </div>
        </div>

        </section>{/* End Hero */}

        <div id="main">
            {/* ======= About Section ======= */}
            <section id="about" className="about">

              <div className="container"  >
                <div className="row gx-0">

                  <div className="col-lg-6 d-flex flex-column justify-content-center"    >
                    <div className="content">
                      <h2>Qui sommes-nous?</h2>
                      <p className="text-justify">
                      DOCARN est une entreprise spécialisée dans la documentation juridique, l’archivage numérique et les prestations informatiques. Notre expérience (de plus de 10 ans) a déjà apporté satisfaction à plus de 50 sociétés et cabinets juridiques au Cameroun par son application web (www.jur.docarn.com) qui regroupe plus de 20 000 actes juridiques Camerounais et plus de 5 000 actes et bulletins d’origines diverses.
                      </p>
                      <div>
                      <NavLink to="/"  className="btn btn-lg btn-primary scrollto ">
                              <span>Lancer une recherche </span>
                              <i className="fa fa-search"></i>
                            </NavLink>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 d-flex align-items-center"  >
                    <img alt="" src={ doc2 } className="img-fluid" />
                  </div>

                </div>
              </div>

            </section>{/* End About Section */}

            {/* ======= Values Section ======= */}
            <section id="values" className="values">

              <div className="container"  >

                <header className="section-header">
                  <h2 className="text-primary">Pourquoi nous choisir?</h2>
                  <p>Nos atouts</p>
                </header>

                <div className="row">

                  

                  <div className="col-lg-4 mt-4 mt-lg-0"  >
                    <div className="box bg-white">
                    <img src={values2} className="img-fluid" alt="" />
                      <h3>Recherche rapide et efficace</h3>
                      <p>
                      DOCARN offre de nombreux accès pour les recherches documentaires avec son algorithme de recherche Par années, mots clés et par catégories...


                      </p>
                    </div>
                  </div>

                  <div className="col-lg-4 mt-4 mt-lg-0"    >
                    <div className="box bg-white">
                    <img src={values3} className="img-fluid" alt="" />
                      <h3>Mises à jour constantes</h3>
                      <p>Les mises à jour et l’insertion des nouveaux actes et documents se font de manière automatique tous les 3 jours.

                      </p>
                    </div>
                  </div>

                  <div className="col-lg-4"    >
                    <div className="box bg-white">
                      <img src={values1} className="img-fluid" alt="" />
                      <h3>Utilisation et abonnement facile  </h3>
                      <p>Fluide, rapide et simple à utiliser, nous vous offrons les formules d'abonnement adaptées à la taille de notre entreprise.</p>
                    </div>
                  </div>

                </div>

              </div>

            </section>{/* End Values Section */}

          
          

            {/* ======= Services Section ======= */}
            <section id="services" className="services">

              <div className="container"  >

                <header className="section-header">
                  <h2>Nous vous offrons</h2>
                  <p>Le contenu de notre base de données</p>
                </header>

                <div className="row gy-4">

                  <div className="col-lg-4 col-md-6" >
                    <div className="service-box blue">
                      <i className="ri-discuss-line icon"></i>
                      <h1>+ 630</h1>
                      <h3>Titre OHADA</h3>
                      <p>+30 N° de J.O. OHADA (2528 pages)</p>
                      
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6"    >
                    <div className="service-box orange">
                      <i className="ri-discuss-line icon"></i>
                      <h1>+ 860</h1>
                      <h3>Titre CEMAC, UEMOA</h3>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6" >
                    <div className="service-box green">
                      <i className="ri-discuss-line icon"></i>
                      <h1>+ 224</h1>
                      <h3>Titre COBAC</h3>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6" >
                    <div className="service-box red">
                      <i className="ri-discuss-line icon"></i>
                      <h1>+ 1421</h1>
                      <h3>Titre J.O Cameroun</h3>
                      <p>6 Bulletins officiels (749 pages)</p>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6"    >
                    <div className="service-box purple">
                      <i className="ri-discuss-line icon"></i>
                      <h1>+ 1967</h1>
                      <h3>DECRETS PR, PM, autres</h3>
                      <p>De 1960 à nos jours (+53 180 pages)</p>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6"   >
                    <div className="service-box pink">
                      <i className="ri-discuss-line icon"></i>
                      <h1>+ 5000</h1>
                      <h3>LOI, ORDONNANCE, CIRCULAIRES, DECISION,NOTES...</h3>
                      <p>De 1960 à nos jours (+90 800 pages)</p>
                    </div>
                  </div>

                </div>

              </div>

            </section>{/* End Services Section */}

            {/* ======= Pricing Section ======= 
            <section id="pricing" className="pricing">

              <div className="container"  >

                <header className="section-header">
                  <h2>Pricing</h2>
                  <p>PACKs d'abonnement annuel</p>
                </header>

                <div className="row gy-4" >

                  <div className="col-lg-3 col-md-6">
                    <div className="box">
                      <h3 >PARTICULIER</h3>
                      <div className="price">200.000 <sup>XAF</sup><span> / An</span></div>
                      <img src={pricing1} className="img-fluid" alt="" />
                      <ul>
                        <li>Accès illimité</li>
                        <li>Session utilisateur: 1</li>
                        <li>Téléchargements Max: 500</li>
                        <li className="na">Téléchargements illimités</li>
                        <li className="na">utilisateurs simultannés</li>
                      </ul>
                      <a href="#" className="btn-buy">Commander maintenant</a>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6"   >
                    <div className="box">
                      <span className="featured">Promotion</span>
                      <h3 >ENTREPRISE CAT-A</h3>
                      <div className="price">450.000 <sup>XAF</sup><span> / An</span></div>
                      <ul>
                        <li className="na">500.000 FCFA/An</li>
                      </ul>
                      
                     
                      <img src={pricing2} className="img-fluid" alt="" />
                      <ul>
                        <li>Accès illimité</li>
                        <li>Session utilisateur: 5</li>
                        <li>Téléchargements Max: 2000</li>
                      </ul>
                      <a href="#" className="btn-buy">Commander maintenant</a>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6"   >
                    <div className="box">
                    <h3 >ENTREPRISE CAT-B</h3>
                      <div className="price">750.000 <sup>XAF</sup><span> / An</span></div>
                      
                      
                     
                      <img src={pricing3} className="img-fluid" alt="" />
                      <ul>
                        <li>Accès illimité</li>
                        <li>Session utilisateur: 7</li>
                        <li>Téléchargements Max: 8000</li>
                        <li>Extra rapide</li>
                      </ul>
                      <a href="#" className="btn-buy">Commander maintenant</a>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6"  data-aos-delay="400">
                    <div className="box">
                    <h3 >ENTREPRISE CAT-C</h3>
                      <div className="price">950.000 <sup>XAF</sup><span> / An</span></div>
                      
                      
                     
                      <img src={pricing4} className="img-fluid" alt="" />
                      <ul>
                        <li>Accès illimité</li>
                        <li>Session utilisateur: illimité</li>
                        <li>Téléchargements: illimité</li>
                        <li>Ultra rapide</li>
                      </ul>
                      <a href="#" className="btn-buy">Commander maintenant</a>
                    </div>
                  </div>

                </div>

              </div>

            </section> */}


            {/* ======= Footer ======= */}
            <div id="footer" className="footer">

              <div className="footer-newsletter">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-12 text-center">
                      <h4>Ils nous font confiance</h4>
                      <p>en s'abonnant à DOCARN Juridique</p>
                    </div>
                    <div className="col-12">
                        <div className="d-flex flex-wrap align-item-center justify-content-between p-3">
                            <div><img src={mtn} width={55} alt="" /></div>
                            <div><img src={sabc} width={55} alt="" /></div>
                            <div><img src={guinness} width={55} alt="" /></div>
                            <div><img src={cotco} width={55} alt="" /></div>
                            <div><img src={afriland} width={55} alt="" /></div>
                            <div><img src={ucb} width={55} alt="" /></div>
                            <div><img src={pwc} width={55} alt="" /></div>
                            <div><img src={edf} width={55} alt="" /></div>
                            <div><img src={mazars} width={55} alt="" /></div>
                            <div><img src={core} width={55} alt="" /></div>
                            <div><img src={brainconsulting} width={55} alt="" /></div>
                            <div><img src={hevecam} width={55} alt="" /></div>
                            <div><img src={globeleq} width={55} alt="" /></div>
                            <div><img src={juritax} width={55} alt="" /></div>
                            <div><img src={notaire} width={55} alt="" /></div>
                            <div><img src={ngue} width={55} alt="" /></div>
                          
                        </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="footer-top">
                <div className="container">
                  <div className="row gy-4">
                    <div className="col-lg-5 col-md-12 footer-info">
                      <a href="index.html" className="logo d-flex align-items-center">
                        <img src="assets/img/logo.png" alt="" />
                        <span className="text-medium">DOCARN</span>
                      </a>
                      <p>Notre expérience (de plus de 10 ans) a déjà apporté satisfaction à plus de 50 sociétés et cabinets juridiques au Cameroun par notre application web (www.jur.docarn.com) </p>
                      <div className="social-links mt-3">
                        <a href="#" className="twitter"><i className="bi bi-twitter"></i></a>
                        <a href="#" className="facebook"><i className="bi bi-facebook"></i></a>
                        <a href="#" className="instagram"><i className="bi bi-instagram"></i></a>
                        <a href="#" className="linkedin"><i className="bi bi-linkedin"></i></a>
                      </div>
                    </div>

                    <div className="col-lg-2 col-6 footer-links">
                      
                    </div>

                    <div className="col-lg-2 col-6 footer-links">
                      <h4>Nos Services</h4>
                      <ul>
                        <li><i className="bi bi-chevron-right"></i> <a href="#">Archivage numérique</a></li>
                        <li><i className="bi bi-chevron-right"></i> <a href="#">Développement Web & Mobile</a></li>
                        <li><i className="bi bi-chevron-right"></i> <a href="#">Management des projets</a></li>
                        <li><i className="bi bi-chevron-right"></i> <a href="#">Marketing Digital</a></li>
                        <li><i className="bi bi-chevron-right"></i> <a href="#">Graphic Design & prototypage</a></li>
                      </ul>
                    </div>

                    <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
                      <h4>Contact</h4>
                      <p>
                        YAOUNDE - DOUALA <br />
                        CAMEROUN
                         <br /><br />
                        <span>Phone:</span> { settings.telApplication }<br />
                        <span>Email:</span> { settings.emailApp } 
                      </p>

                    </div>

                  </div>
                </div>
              </div>

              <div className="container">
                <div className="copyright">
                  &copy; { settings.copyright }. All Rights Reserved
                </div>
                <div className="credits">
                  {/* All the links in the footer should remain intact. */}
                  {/* You can delete the links only if you purchased the pro version. */}
                  {/* Licensing information: https://bootstrapmade.com/license/ */}
                  {/* Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/flexstart-bootstrap-startup-template/ */}
                  Designed by <a href="https://docarn.com/">DOCARN</a>
                </div>
              </div>
            </div>{/* End Footer */}

        </div>
                
    </div>
  );
};

export default About;

