import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import API from '../../api/apiKit';
import { getAsyncDataTable, getCurrentPage, getLinks, getNextPageUrl, getPaginate, getPrevPageUrl, setCurrentPage, setData, setLinks, setNextPageUrl, setPrevPageUrl, setSizePage } from '../../store/page/PageSlice';
import NumberPage from './NumberPage';

const PaginationComponent = ({handleChangePage }) => {

    const dispatch = useDispatch();
    const [nextUrl, setNextUrl] = useState(1);

    const pageData  = useSelector(getPaginate);// default page size
    const links = useSelector(getLinks);
    const currentPage = useSelector(getCurrentPage);
    const prevPageUrl = useSelector(getPrevPageUrl);
    const nextPageUrl = useSelector(getNextPageUrl);
    const numbers  = links.length;

    let tab = [];

    const items = links.map((item, index) => { 
        return <NumberPage handleChangePage={handleChangePage} nextUrl={nextUrl} item={item} index={item.label} key={index} />;
    });
    
      // useEffect(() => {
      //   handleChangePage(nextUrl);
      // },[nextUrl]);


    return (
        <>
          {
              numbers > 3
              &&
              <>
                  { prevPageUrl && <strong className="pointer" onClick={() => handleChangePage(prevPageUrl)}> {"< Précédent"} </strong> }
                  { items }
                  { nextPageUrl && <strong className="pointer" onClick={() => handleChangePage(nextPageUrl)}> {"Suivant >"} </strong> }
              </>
          }
        </>
    );
};

export default PaginationComponent;