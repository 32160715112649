import { createSlice } from "@reduxjs/toolkit";

export const UserSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    authentificated: false,
    authentificatedAdmin: false,
    dataUser: null
  },
  reducers: {
    setUser: (state,action) => {
      state.user = action.payload;
    },
    setAuthentificated: (state,action) => {
      state.authentificated = action.payload;
    },
    setAuthentificatedAdmin: (state,action) => {
      state.authentificatedAdmin = action.payload;
    },
    setDataUser: (state,action) => {
      state.dataUser = action.payload;
    },
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.authentificated = false;
      state.authentificatedAdmin = false;
      localStorage.clear();
      // toastDocarnJuridique("Vous êtes maintenant déconnecté.", settings.toastDuration, "success");
    }
  }
});

export const { setUser, setAuthentificated, setAuthentificatedAdmin, setDataUser, logout } = UserSlice.actions;
export const selectCurrentUser = (state) => state.user.user;
export const getAuthentificated = (state) => state.user.authentificated;
export const getAuthentificatedAdmin = (state) => state.user.authentificatedAdmin;
export const getDataUser = (state) => state.user.dataUser;
export default UserSlice.reducer;
