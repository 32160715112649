export const getToken = (name) => {
 return localStorage.getItem(name);
 
}

export const setToken = (name, valeur) => { console.log(valeur);
     localStorage.setItem(name, valeur);
}

export const removeToken = (name) => {
    localStorage.clear();
}



