import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api/apiKit";


export const PageSlice = createSlice({
  name: "page",
  initialState: {
    isLoader: false,
    textAide: "",
    textRecherche: "",
    table:{
      columns:[],
      data:[]
    },
    paginate:{
      baseUrl: null,
      countPage: 2,
      currentPage: 1,
      prevPageUrl: null,
      nextPageUrl: null,
      sizePage: 5,
      links: [],
    },
    cart:{
      status: "open",
      items:[],
    },
    status:'idle'
  },
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setTextAide: (state,action) => {
      state.textAide = action.payload;
    },
    setIsLoader: (state,action) => {
      state.isLoader = action.payload;
    },
    setTextRecherche: (state,action) => {
      state.textRecherche = action.payload;
    },
    setTable: (state,action) => {
      state.table = action.payload;
    },
    setColumnsTable:(state,action) => {
      state.table.columns = action.payload;
    },
    setData:(state,action) => {
      state.table.data = action.payload;
    },
    setSateData:(state,action) => {

      // newData = [...data];
      state.table.data[action.payload.index].state = action.payload.state;
      //   setData(newData);
      // state.table.data = action.payload;
    },
    setFavoryData:(state,action) => {

      // newData = [...data];
      state.table.data[action.payload.index].favory = action.payload.favory;
      //   setData(newData);
      // state.table.data = action.payload;
    },
    setPaginateBaseUrl:(state,action) => {
      state.paginate.baseUrl = action.payload;
    },
    setLinks:(state,action) => {
      state.paginate.links = action.payload;
    },
    setPrevPageUrl:(state,action) => {
      state.paginate.prevPageUrl = action.payload;
    },
    setNextPageUrl:(state,action) => {
      state.paginate.nextPageUrl = action.payload;
    },
    setSizePage:(state,action) => {
      state.paginate.sizePage = action.payload;
    },
    setCurrentPage:(state,action) => {
      state.paginate.currentPage = action.payload;
    },
  },
});

export const {
  setIsLoader,
  setCartStatus,
  addOrModifyCartItem,
  removeCartItem,
  setTextAide, 
  setTextRecherche, 
  setTable,
  setColumnsTable,
  setData,
  setSateData,
  setFavoryData,
  setCurrentPage,
  setLinks,
  setPrevPageUrl,
  setNextPageUrl,
  setSizePage,
  setPaginateBaseUrl,
  addOrModifyDataTable
} = PageSlice.actions;


export const getIsLoader = (state) => state.page.isLoader;
export const getPageStatus = (state) => state.page.status;
export const getCartStatus = (state) => state.page.cart.status;
export const getCartItems = (state) => state.page.cart.items;

export const getTextAide = (state) => state.page.textAide;
export const getTextRecherche = (state) => state.page.textRecherche;
export const getColumnsTable = (state) => state.page.table.columns;
export const getData = (state) => state.page.table.data;
export const getPaginateBaseUrl = (state) => state.page.paginate.baseUrl;
export const getLinks = (state) => state.page.paginate.links;
export const getPrevPageUrl = (state) => state.page.paginate.prevPageUrl; 
export const getCurrentPage = (state) => state.page.paginate.currentPage; 
export const getNextPageUrl = (state) => state.page.paginate.nextPageUrl;
export const getPaginate = (state) => state.page.paginate;

export default PageSlice.reducer;
