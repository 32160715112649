import React from "react";
import { useSelector } from "react-redux";
import Body from "../components/Body";
import Header from "../components/Header";
import { CONSTS } from "../config/constants";
import { getAuthentificated, getAuthentificatedAdmin } from "../store/user/UserSlice";

const AdminGestUsers = () => {
  const authenticated = useSelector(getAuthentificated);
  const isAdmin = useSelector(getAuthentificatedAdmin);

  return (
    <div>
      <Header page={CONSTS._USERS} />
      { (authenticated === true && isAdmin) ? (
        <>
          <Body activePage={CONSTS._USERS} />
        </>
      ) : (
        <>
          <Body activePage={CONSTS._HOME} />
        </>
      )}
    </div>
  );
};

export default AdminGestUsers;
