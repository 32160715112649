import React, { useEffect, useState } from "react";
import profil from "../assets/img/profil.svg";
import logo from "../assets/img/logo.png";
import Select from "react-select";
import './style.css';
import { infoFormResAPI } from "../api/docarnJuridiqueApi";
import LoginModal from "../components/auth/LoginModal";
import RegisterModal from "../components/auth/RegisterModal";
import { useDispatch } from "react-redux";
import { setCategoryState, setSearchState, setYearState } from "../store/search/SearchSlice";
import { NavLink, useHistory } from "react-router-dom/cjs/react-router-dom";
import settings from "../config/settings";
const Welcome = () => {
  const [yearsOption, setYearsOption] = useState("");
  const [years, setYears] = useState("");
  const [categoriesOption, setCategoriesOption] = useState("");
  const [categories, setCategories] = useState("");
  const [search, setSearch] = useState("");
  const history = useHistory();

  useEffect(() => { 
      infoFormResAPI()
        .then(rep => {

          const yearsOption = rep.data.data.years.map((items) => {
            return <option key={items.publication} value={items.publication}>{items.publication}</option>
          });

          setYearsOption(yearsOption);

          const categorieOption = rep.data.data.categorys.map((items) => {
            return <option key={items.label} value={items.label}>{items.label}</option>
          });

          setCategoriesOption(categorieOption);
        })
        .catch(err => {
          console.log(err);
        })
  }, []);

  const dispatch = useDispatch();
  const handleSubmitSearch = () => (event) => {
    event.preventDefault();
    if (search != null && search !== "") {
      dispatch(setSearchState(search));
      dispatch(setCategoryState(categories));
      dispatch(setYearState(years));
      history.push('/home');
      // document.location.href=`/home?y=${years}&c=${categories}&s=${search}`;
    }
    
  };
  
  return (
    <div className="fond">
      <LoginModal />
      <RegisterModal />
      <div className="Container p-3">
        <div className="d-flex align-item-center justify-content-between">
          <div className="h1">
          <img alt="" src={ logo } width={100} />
          </div>
          <div className="d-flex align-item-center justify-content-between p-2">
            <div>
            <NavLink to="/about"  className="btn btn-primary">A propos</NavLink>
            &nbsp;&nbsp;
              <a href="/#"  data-target="#RegisterModal" data-toggle="modal" className="a-noline">Inscription  </a>&nbsp;&nbsp;&nbsp; 
              <a href="/#"  data-target="#LoginModal" data-toggle="modal" className="a-noline">Connexion <img alt="" src={ profil } width={22} /> </a>
            </div>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmitSearch()}>
      <div className="d-flex align-item-center justify-content-center corp">
          <div className="text-center text-white">
              <div className="titre-accueil">Bienvenue dans <strong>DOCARN Juridique</strong></div><br/>
              <div className="titre-accueil2">Toute la documentation Juridique du CAMEROUN depuis 1960 à nos jours... </div>

              <div className="row mb-30 ">
                <div className="col-6">
                <select className="select-style" onChange={(e) => setYears(e.target.value)}>
                  <option value="">Toutes les années</option>
                  {yearsOption}
                </select>
                </div>
                <div className="col-6">
                  <select className="select-style" onChange={(e) => setCategories(e.target.value)}>
                  <option value="">Toutes les catégories</option>
                    {categoriesOption}
                  </select>
                </div>
              </div>
          </div>
      </div>
      <div className="row d-flex justify-content-center align-item-center  p-3">
              <div className="col-md-6">
                  <div className="input-group">
                    <input type="search" required className="form-control search" onChange={(e) => setSearch(e.target.value)} placeholder="Saisir l'élément de recherche..." />
                    <button className="btn btn-primary" onClick={handleSubmitSearch()}>  <i className="fa fa-search"></i> </button>
                  </div>
              </div>
      </div>
      </form>
      <div className="row fixed-bottom d-flex justify-content-center align-item-center  p-3">
                <div className="col-12 text-center p-3 text-white">
                    
                    <div className="text-bold-200">{ settings.telApplication  }</div>
                    <div>{ settings.emailApp }</div>
                </div>
      </div>
      
    </div>
  );
};

export default Welcome;

