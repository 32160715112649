import { createSlice } from "@reduxjs/toolkit";
import settings, { toastDocarnJuridique } from "../../config/settings";
import setAuthToken from "../../utils/setAuthToken";

export const SearchSlice = createSlice({
  name: "search",
  initialState: {
    search: null,
    category: null,
    year: null,
  },
  reducers: {
    setSearchState: (state,action) => {
      state.search = action.payload;
    },
    setCategoryState: (state,action) => {
      state.category = action.payload;
    },
    setYearState: (state,action) => {
      state.year = action.payload;
    }
  }
});

export const { setSearchState, setCategoryState, setYearState } = SearchSlice.actions;
export const getSearchState = (state) => state.search.search;
export const getYearState = (state) => state.search.year;
export const getCategoryState = (state) => state.search.category;
export default SearchSlice.reducer;
