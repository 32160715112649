import React from "react";
import "./Item.style.css";
import logApp from "../../../utils/logApp";
import { useSelector } from "react-redux";
import { getAuthentificated } from "../../../store/user/UserSlice";
import SpinnerApp from "../../spinner/SpinnerApp";
import LoaderPage from "./LoaderPage";
import { getIsLoader } from "../../../store/page/PageSlice";

const TrLineHome = (prop) => {
  const authenticated = useSelector(getAuthentificated);
  const isLoader = useSelector(getIsLoader);
  const Document = prop.document;
  const Index = prop.index;;
  logApp('ReLine.jsx', 'ligne du tableau', Document)
  return (
    <>
    {
      isLoader && <LoaderPage message={"Action en cours..."} />
    }
      <tr>
        <td>
          {/* <h6 className="user-title" style={"color: #440e62"}> */}
          <h6 className="Item_style">
            {Document.category}&nbsp;N°{Document.number} &nbsp;du &nbsp;
            {Document.datepublication}
          </h6>
          <strong>{Document.description}</strong>
        </td>
        <td>
          {/* <a href="https://www.jur.docarn.com/ced_connexion"style="color: red;"><i className="fa fa-download" style="color: red; font-size: 20px"> </i> Abonnez-vous pour télécharger </a> */}
          {authenticated ? (
            <>
            <button className="btn btn-primary" onClick={() => prop.handleSubmitDownloadFile(Document, Index)}>
                <i className="fa fa-download"> </i> Télécharger
              </button>
            
              <br/><br/>

              {Document.favory ? (
                <button className="btn btn-warning" onClick={() => prop.handleAddFavory(Document, false, Index)}>
                  <i className="fa fa-star"> </i> Retirer
                </button>
              ) : (
                <button className="btn btn-warning" onClick={() => prop.handleAddFavory(Document, true, Index)}>
                  <i className="fa fa-star"> </i> Ajouter
                </button>
              )}
            </>)
            :
            <>
            <button  data-target="#LoginModal" data-toggle="modal" className="btn btn-primary">
                <i className="fa fa-sign-in"> </i> Se connecter
              </button>
            </>
           }
        </td>
      </tr>
    </>
  );
};

export default TrLineHome;
